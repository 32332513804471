import * as Type from "../actions/images";

export default function images(state = { loadingImages: false }, action) {
    if (action.type === Type.UPLOADING_IMAGES) {
        return {
            loadingImages: action.payload
        };
    }

    return {
        ...state
    };
};
