export default function getOrganisationProps(state = {}, props = {}) {
    const params = (props.params || {});


    const organisationId = !!params.organisationId ? params.organisationId : state.settings.organisation;
    let organisation = state.organisations[organisationId];
    if (!organisation) {
        organisation = state.userOrganisations[organisationId] || {};
    }

    const charityInfo = state.charityInfo[organisationId] || {};

    return {
        organisationId: organisationId,
        organisation: organisation,
        charityInfo: charityInfo,
        stripeAccount: state.stripe.stripeAccount
    };
}
