import React, { Component } from "react";
import { Dialog as MatDialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import * as classnames from "classnames";
import styles from "./Dialog.module.scss";
import Button from "../button/Button";

export default class Dialog extends Component {

    state = {
        show: false
    };

    id = uuid();


    show = () => {
        this.setState({
            show: true
        });
    };

    hide = () => {
        if (!this.props.loading) {
            this.setState({
                show: false
            });
        }
    };

    actionToHide = () => {
        if (!this.props.disableDismiss) {
            this.props.actionToHide();
            this.hide();
        }
    };

    renderActionButton = (config, i) => {

        if (!config) {
            return null;
        }

        return (
            <Button key={i} onPress={config.onPress} {...config.props}>{config.text}</Button>
        );
    };

    renderChildren = () => {
        let children = this.props.children;
        if (typeof children === "function") {
            children = children(this.state.params);
        }

        return children;
    };

    render() {
        const rootClassName = classnames(styles.root, this.props.rootClassName);
        const containerClassName = classnames(styles.container, this.props.containerClassName);
        const className = classnames(styles.dialog, this.props.className);
        const titleClassName = classnames(styles.dialog_title, this.props.titleClassName);
        const contentClassName = classnames(styles.dialog_content, this.props.contentClassName);
        const actionsClassName = classnames(styles.dialog_actions, this.props.actionsClassName);

        let actions = this.props.actions;
        if (typeof actions === "function") {
            actions = actions(this.state.params);
        }

        return (
            <MatDialog
                open={!!this.props.isControlled ? !!this.props.shown : !!this.state.show}
                onClose={this.actionToHide}
                onBackdropClick={this.actionToHide}
                classes={{
                    container: containerClassName,
                    paper: className
                }}
                className={rootClassName}
                aria-labelledby={this.id}>
                {!!this.props.title
                    ? (
                        <DialogTitle id={this.id}
                                     className={titleClassName}>{this.props.title}
                        </DialogTitle>
                    )
                    : null}

                <DialogContent
                    className={classnames(contentClassName)}>
                    {!!this.props.text ? (
                        <DialogContentText
                            className={styles.dialog_content_text}>{this.props.text}
                        </DialogContentText>
                    ) : null}
                    {this.renderChildren()}
                    {!!this.props.error ? (
                        <DialogContentText
                            className={styles.dialog_content_text_error}>{this.props.error}
                        </DialogContentText>
                    ) : null}
                </DialogContent>

                {!!(this.props.actions || []).length ? (
                    <DialogActions className={actionsClassName}>
                        {actions.map(this.renderActionButton)}
                    </DialogActions>
                ) : null}

            </MatDialog>
        );
    }
}

Dialog.propTypes = {
    rootClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    actionsClassName: PropTypes.string,

    isControlled: PropTypes.bool,
    shown: PropTypes.bool,
    actionToHide: PropTypes.func,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool,

    error: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    actions: PropTypes.array,

    width: PropTypes.oneOf(["xs", "sm", "md", "lg", false]),
    fullWidth: PropTypes.bool,
    disableDismiss: PropTypes.bool,
    custom: PropTypes.bool
};

Dialog.defaultProps = {
    rootClassName: null,
    containerClassName: null,
    titleClassName: null,
    contentClassName: null,
    actionsClassName: null,

    isControlled: false,
    shown: false,
    actionToHide: () => null,
    onSubmit: null,

    error: null,
    title: null,
    text: null,
    actions: [],

    width: "xs",
    fullWidth: true,
    disableDismiss: false,
    custom: false
};
