import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, Formik } from "formik";
import PropTypes from "prop-types";
import classnames from "classnames";
import FieldSet from "../../../widgets/fieldSet/FieldSet";
import Input from "../../../widgets/input/textInput/Input";
import Dialog from "../../../widgets/dialog/Dialog";
import styles from "./OnBoardingDialog.module.scss";
import { showOnBoardingDialog } from "../../../../redux/actions/auth";
import Error from "../../../widgets/error/Error";
import withRouter from "../../../../util/hooks/withRouter";
import { FirebaseContext } from "../../../../context/firebase";

class OnBoardingDialog extends Component {
    static contextType = FirebaseContext;

    state = {
        loading: false,
        error: null,
    };

    onRef = (ref) => {
        this.dialog = ref;
        this.props.dialogRef(ref);
    };

    hide = () => {
        this.dialog.hide();
    };

    onHide = () => {
        this.props.dispatch(showOnBoardingDialog(false));
    };

    renderDialog = (formProps) => {
        const actions = [
            {
                text: "Submit",
                onPress: formProps.handleSubmit,
                props: {
                    disabled: (!formProps.isValid || !formProps.dirty),
                    loading: this.state.loading
                }
            }
        ];

        const className = classnames(styles.on_boarding_dialog, this.props.className);

        return (
            <Dialog className={ className }
                actions={ actions }
                isControlled
                actionToHide={ this.onHide }
                shown={ this.props.showOnBoardingDialog }
                title={ "Please choose a password for this account" }
                loading={ this.state.loading }
                disableDismiss
                ref={ this.onRef }>
                <FieldSet label={ "Password" }>
                    <Field name={ "password" }
                        component={ Input }
                        type={ "password" }
                        placeholder={ "" } />
                </FieldSet>
                <FieldSet label={ "Confirm password" }>
                    <Field name={ "confirmPassword" }
                        component={ Input }
                        type={ "password" }
                        placeholder={ "" }
                    />
                </FieldSet>

                <Error error={ this.state.error } />
            </Dialog>
        );
    };

    validate = (values) => {
        const errors = {};

        if (!values.password) {
            errors.password = "Required";
        }

        if (values.password && !values.confirmPassword) {
            errors.confirmPassword = "Required";
        }

        if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
            errors.confirmPassword = "Passwords don't match";
        }

        if (values.password && values.password.length < 6) {
            errors.password = "Password must have more than 6 characters";
        }

        return errors;
    };

    onSubmit = (values) => {

        this.setState({
            loading: true
        });

        this.context.updatePassword(values.password)
            .then(() => {
                this.setState({
                    loading: false
                });
                this.dialog.hide();
                this.props.dispatch(showOnBoardingDialog(false));
            }).catch((error) => {
                this.setState({
                    loading: false,
                    error: error.message
                });
            });

    };

    render() {
        return (
            <Formik initialValues={ { password: "", confirmPassword: "" } }
                enableReinitialize
                validate={ this.validate }
                onSubmit={ this.onSubmit }
            >
                { (props) => this.renderDialog(props) }
            </Formik>
        );
    }
}

OnBoardingDialog.propTypes = {
    className: PropTypes.string,
    dialogRef: PropTypes.func
};

OnBoardingDialog.defaultProps = {
    className: null,
    dialogRef: () => null
};

export default connect((state) => {
    return {
        showOnBoardingDialog: state.auth.showOnBoardingDialog,
    };
})(withRouter(OnBoardingDialog));
