import * as Types from "../actions/auth";

const initialState = {
    showOnBoarding: false,
    showOnBoardingDialog: false
};

export default function auth(state = initialState, action = { type: null, payload: null }) {
    switch (action.type) {
        case Types.SHOW_ONBOARDING_DIALOG: {
            return {
                ...state,
                showOnBoardingDialog: !!action.payload
            };
        }

        case Types.SHOW_ONBOARDING: {
            return {
                ...state,
                showOnBoarding: !!action.payload
            };
        }
        default: {
            return state;
        }
    }
}
