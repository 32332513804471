/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-13.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import classnames from "classnames";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React from "react";
import styles from "./Input.module.scss";

const Input = React.forwardRef((props, ref) => {

    const className = classnames(styles.input, {
        [styles.disabled]: props.disabled
    }, props.className);

    const newProps = omit(props, [...Object.keys(Input.defaultProps), "children", "field"]);

    if (!!props.password) {
        newProps.type = "password";
    }

    return (
        <div className={className}>
            {props.label && <label htmlFor={props.id}>{props.label}</label>}
            <div className={styles.input_wrapper}>
                <input {...newProps}
                       {...(props.field || {})}
                       ref={ref}/>
            </div>
            {props.children}
            {props.form && props.form.touched[props.field.name] && props.form.errors[props.field.name] && <div>{props.form.errors[props.field.name]}</div>}
        </div>
    );
});

Input.propTypes = {
    className: PropTypes.string,
    password: PropTypes.bool
};

Input.defaultProps = {
    className: null,
    password: false
};

export default Input;
