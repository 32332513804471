/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-13.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import classnames from "classnames";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React from "react";
import { renderComponent } from "../../../util/renderComponent";
import Label from "../label/Label";
import styles from "./FieldSet.module.scss";

function FieldSet(props) {
    const className = classnames(styles.field_row, props.className);

    const childProps = omit({ ...props }, [...Object.keys(FieldSet.defaultProps)]);

    return (
        <div className={className}>
            <div className={styles.label_wrapper}>
                <Label className={styles.label}>{props.label}</Label>
            </div>
            <div className={styles.fields}>
                {renderComponent(props.children, childProps)}
            </div>
            {!!props.content ? (
                <div className={styles.content}>
                    {renderComponent(props.content, childProps)}
                </div>
            ) : null}
        </div>
    );
}

FieldSet.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    content: PropTypes.any
};

FieldSet.defaultProps = {
    className: null,
    label: null,
    content: null,
};

export default FieldSet;