export function getUserTransactionsAPI(userId, params) {
    return window.get(`v1/users/${userId}/transactions`, params);
}

export function getOrganisationTransactionsAPI(organisationId, params) {
    return window.get(`v1/organisations/${organisationId}/transactions`, params);
}

export function getUserEOFYReceipts(userId, cursor) {
    return window.get(`v1/admin/users/${userId}/eofy`, { cursor });
}
