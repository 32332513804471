import * as ImagesAPI from "./api/images";
import wrapper from "./helpers/wrapper";
import { showError } from "./snackbars";
import createAction from "./helpers/createAction";

export const UPLOADING_IMAGES = "images:UPLOADING_IMAGES";

export const uploadingImages = createAction(UPLOADING_IMAGES);

export const uploadImages = wrapper(ImagesAPI.uploadImagesAPI, () => {
}, (dispatch, e) => {
    dispatch(showError(e.message));
});