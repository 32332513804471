import * as Types from "../actions/organisations";

const initialState = {};

export default function charityInfo(state = initialState, action = { type: null, payload: null }) {
    switch (action.type) {

        case Types.SET_ORGANISATION_INFO: {

            if (!action.payload || !action.payload.charity) {
                return {
                    ...state
                };
            }

            return {
                ...state,
                [ action.payload.charity ]: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}
