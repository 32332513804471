import { Chip as MatChip } from "@mui/material";
import classnames from "classnames";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React from "react";
import styles from "./Chip.module.scss";


function Chip(props) {

    const className = classnames(styles.chip, {
        [styles.blue]: props.blue,
        [styles.red]: props.red,
        [styles.green]: props.green,
        [styles.purple]: props.purple,
        [styles.orange]: props.orange
    }, props.className);

    const newProps = omit(props, [...Object.keys(Chip.defaultProps), "children"]);

    return (
        <MatChip label={props.children} {...newProps} className={className} />
    );
}

Chip.propTypes = {
    className: PropTypes.string,
    blue: PropTypes.bool,
    red: PropTypes.bool,
    green: PropTypes.bool,
    purple: PropTypes.bool,
    orange: PropTypes.bool
};

Chip.defaultProps = {
    className: null,
    blue: false,
    red: false,
    green: false,
    purple: false,
    orange: false
};

export default Chip;
