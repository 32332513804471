
const User = {
    root: "/users/:userId",
    Donations: "/users/:userId/donations"
};

const Users = {
    root: "/",
    User: User,
};

const OrganisationDetails = {
    root: "/organisations/:organisationId/details",
    BankAccountDetails: "/organisations/:organisationId/details/bank-account",
    DonationReceipts: "/organisations/:organisationId/details/receipts",
    Regions: "/organisations/:organisationId/details/regions",
};

const OrganisationTransactions = {
    root: "/organisations/:organisationId/transactions",
};

const OrganisationReports = {
    root: "/organisations/:organisationId/reports",
};

const Organisation = {
    root: "/organisations/:organisationId",
    OrganisationDetails: OrganisationDetails,
    OrganisationTransactions: OrganisationTransactions,
    OrganisationReports: OrganisationReports,
    OrganisationPage: "/organisations/:organisationId/page"
};

const Organisations = {
    root: "/organisations",
    Organisation: Organisation,
    OnBoarding: "*",
};

const CharityPage = {
    root: "/charity/page",
    PagePreview: "/charity/page/preview",
    EditPage: "/charity/page/edit",
};

const Charity = {
    root: "/charity",
    CharityPage: CharityPage
};

const Account = {
    root: "/account",
    DonationReceipts: "/account/donation-receipts",
    Regions: "/account/regions",
    BankDetails: "/account/bank-details",
    Charity: "/account/charity"
};

const Routes = {
    root: "/",
    Loading: "*",
    Users: Users,
    Charity: Charity,
    Account: Account,
    Transactions: "/transactions",
    Organisations: Organisations,
};

export default Routes;
