export const PER_PAGE_OPTIONS = Object.freeze({
    10: {
        label: "10",
        value: 10
    },
    25: {
        label: "25",
        value: 25
    },
    50: {
        label: "50",
        value: 50
    },
    100: {
        label: "100",
        value: 100
    },
    250: {
        label: "250",
        value: 250
    },
    500: {
        label: "500",
        value: 500
    },
    1000: {
        label: "1000",
        value: 1000
    },
    2000: {
        label: "2000",
        value: 2000
    }
});