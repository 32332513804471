import * as Types from "../actions/organisations";

const initialState = {};

export default function organisations(state = initialState, action = { type: null, payload: null }) {
    switch (action.type) {

        case Types.SET_ORGANISATION: {
            const newState = { ...state };
            const organisation = (action.payload || {});
            newState[ organisation.id ] = organisation;

            return newState;
        }

        case Types.SET_ORGANISATIONS: {
            const newState = { ...state };
            // eslint-disable-next-line no-shadow
            const organisations = (action.payload || []);
            organisations.forEach((organisation) => {
                newState[ organisation.id ] = organisation;
            });
            return newState;
        }
        default: {
            return state;
        }
    }
}
