import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, Formik } from "formik";
import validUrl from "valid-url";
import styles from "./CharityDetails.module.scss";
import Input from "../../input/textInput/Input";
import FileInput from "../../input/fileInput/FileInput";
import Button from "../../button/Button";
import Loading from "../../../routes/loading/Loading";
import { updateCharityInfo } from "../../../../redux/actions/organisations";
import getOrganisationProps from "../../../../util/getOrganisationProps";
import Component from "../../component/Component";
import NumberInputField from "../../input/numberInput/NumberInputField";
import withRouter from "../../../../util/hooks/withRouter";

class CharityDetails extends Component {

    state = {
        loading: false
    };

    onSubmit = (values) => {

        const data = {
            ...this.props.charityInfo,
            ...values
        };

        if (data.phoneNumber) {
            data.phoneNumber = data.phoneNumber.trim();
        }

        this.dispatch(updateCharityInfo, {
            organisationId: this.props.organisationId,
            data: data,
        }, {
            onSuccess: () => {
                if (this.props.nextPage) {
                    this.props.nextPage();
                }
            }
        });
    };

    validate = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = "Required";
        }

        if (!values.phoneNumber) {
            errors.phoneNumber = "Required";
        }

        if (!values.websiteUrl) {
            errors.websiteUrl = "Required";
        }

        if (values.websiteUrl && !validUrl.isWebUri(values.websiteUrl)) {
            errors.websiteUrl = "Invalid website url. Please make sure you put http:// at the start";
        }

        if (!values.logoUrl) {
            errors.logoUrl = "Required";
        }

        if (!values.charityNo) {
            errors.charityNo = "Required";
        }

        // if (!values.blurb) {
        //     errors.blurb = "Required";
        // }
        //
        // if (values.blurb && values.blurb.length > 160) {
        //     errors.blurb = "Max character limit is 150 characters";
        // }
        //
        // if (!values.cardUrl) {
        //     errors.cardUrl = "Required";
        // }

        return errors;
    };

    renderForm = (formProps) => {
        return (
            <div className={styles.charity_details}>
                <div className={styles.form_container}>
                    <div className={styles.form_content}>
                        <h2>Charity Details</h2>

                        <Field id={"name"}
                               name={"name"}
                               label={"Charity Name"}
                               placeholder={"Charity Name"}
                               component={Input}
                        />

                        <NumberInputField name={"phoneNumber"}
                                          label={"Charity Phone Number"}
                                          numberFormatProps={{
                                              format: "+64##########"
                                          }}
                        />

                        <Field id={"websiteUrl"}
                               name={"websiteUrl"}
                               label={"Charity Website"}
                               placeholder={"Charity Website"}
                               component={Input}
                        />

                        <Field elementId={"logoUrl"}
                               name={"logoUrl"}
                               label={"Charity Logo"}
                               component={FileInput}
                        />

                        <Field id={"charityNumber"}
                               name={"charityNo"}
                               label={"Charity Number"}
                               placeholder={"Charity Number"}
                               component={Input}
                        />

                        <Field id={"gstNo"}
                               name={"gstNo"}
                               label={"GST Number"}
                               placeholder={"GST Number"}
                               component={Input}
                        />

                        <Field id={"receiptPersonName"}
                               name={"receiptPersonName"}
                               label={"Signatory Name"}
                               placeholder={""}
                               component={Input} />

                        <Field id={"receiptPersonTitle"}
                               name={"receiptPersonTitle"}
                               label={"Signatory Position"}
                               placeholder={"CEO"}
                               component={Input} />

                        <Field id={"receiptSignatureUrl"}
                               name={"receiptSignatureUrl"}
                               label={"Signature"}
                               component={FileInput} />
                    </div>
                </div>
                <div className={styles.button}>
                    <Button onPress={formProps.handleSubmit} blue disabled={this.props.loadingImages}>
                        {this.props.submitButtonText ? this.props.submitButtonText : "Continue"}
                    </Button>
                </div>
            </div>
        );
    };

    render() {

        if (this.state.loading) {
            return (
                <Loading />
            );
        }

        return (
            <Formik initialValues={this.props.initialValues}
                    enableReinitialize
                    validate={this.validate}
                    onSubmit={this.onSubmit}
            >
                {(props) => this.renderForm(props)}
            </Formik>
        );
    }
}

CharityDetails.propTypes = {
    className: PropTypes.string,
};

CharityDetails.defaultProps = {
    className: null,
};

export default withRouter(connect((state, props) => {

    const organisationProps = getOrganisationProps(state, props);
    let charityName = "";
    let logoUrl = "";
    let charityNo = "";
    let gstNo = "";
    // let cardUrl = "";
    // let blurb = "";
    let websiteUrl = "";
    let phoneNumber = "";
    let receiptPersonTitle = "";
    let receiptPersonName = "";
    let receiptSignatureUrl = "";

    if (!!organisationProps.charityInfo) {
        logoUrl = (organisationProps.charityInfo.logoUrl || "");
        charityNo = (organisationProps.charityInfo.charityNo || "");
        gstNo = (organisationProps.charityInfo.gstNo || "");
        // cardUrl = organisationProps.charityInfo.cardUrl || "";
        // blurb = organisationProps.charityInfo.blurb || "";
        charityName = organisationProps.charityInfo.name || organisationProps.organisation.name || "";
        websiteUrl = organisationProps.charityInfo.websiteUrl || "";
        phoneNumber = organisationProps.charityInfo.phoneNumber || "";
        receiptPersonTitle = organisationProps.charityInfo.receiptPersonTitle || "";
        receiptPersonName = organisationProps.charityInfo.receiptPersonName || "";
        receiptSignatureUrl = organisationProps.charityInfo.receiptSignatureUrl || "";
    }

    return {
        initialValues: {
            name: charityName,
            logoUrl: logoUrl,
            charityNo: charityNo,
            gstNo: gstNo,
            // cardUrl: cardUrl,
            // blurb: blurb,
            websiteUrl: websiteUrl,
            phoneNumber: phoneNumber,
            receiptSignatureUrl,
            receiptPersonTitle,
            receiptPersonName
        },
        loadingImages: state.images.loadingImages,
        ...organisationProps
    };
})(CharityDetails));
