import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import styles from "./Login.module.scss";
import Button from "../../widgets/button/Button";
import Input from "../../widgets/input/textInput/Input";
import Logo from "../../../resources/images/png/share_my_super_logo.png";
import { Card } from "@mui/material";
import withRouter from "../../../util/hooks/withRouter";
import { useFirebase } from "../../../context/firebase";

function Login(props) {
    const [ loading, setLoading ] = useState(false);
    const { signIn } = useFirebase();
    const { user, navigate, authLoading } = props;

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [ user, navigate ]);

    const onSubmit = async (values) => {
        setLoading(true);
        await signIn(values.email, values.password);
        setLoading(false);
    };
    console.log(loading, authLoading);

    return (
        <div className={ styles.login_container }>
            <img className={ styles.logo } src={ Logo } alt={ "Share my Super" } />
            <Card className={ styles.login_content_container }>
                <h2>Log in</h2>
                <Formik enableReinitialize
                    onSubmit={ onSubmit }
                    initialValues={ {
                        email: "",
                        password: ""
                    } }>
                    { (formProps) => (
                        <form onSubmit={ formProps.handleSubmit } className={ styles.form }>
                            <div className={ styles.input }>
                                <Input name={ "email" }
                                    value={ formProps.values.email }
                                    placeholder={ "Email" }
                                    onChange={ formProps.handleChange } />
                                <Input name={ "password" }
                                    password
                                    placeholder={ "Password" }
                                    onChange={ formProps.handleChange } />
                            </div>

                            <Button loading={ loading || authLoading }
                                type={ "submit" }>
                                Log in
                            </Button>
                        </form>
                    ) }
                </Formik>
            </Card>
        </div>
    );
}

export default connect((state) => {
    return {
        user: state.user,
        authLoading: state.settings.authLoading
    };
})(withRouter(Login));
