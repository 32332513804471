import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Routes from "../../../util/routes";
import { getRoutes } from "../../../util/getRoutes";
import matchPath from "../../../util/matchPath";
import styles from "./NavigationWrapper.module.scss";
import { renderComponent } from "../../../util/renderComponent";
import Logo from "../../../resources/images/png/share_my_super_logo.png";
import { AppBar, CssBaseline, ListItemText, MenuItem, Toolbar } from "@mui/material";
import withRouter from "../../../util/hooks/withRouter";
import { FirebaseContext } from "../../../context/firebase";
import { persist } from "../../../App";
import { PERSIST_CONFIG } from "../../../redux/reducers/root";
import { purgeReduxStore, setAuthLoading } from "../../../redux/actions/settings";

class NavigationWrapper extends Component {
    static contextType = FirebaseContext;

    static propTypes = {
        isAdmin: PropTypes.bool
    };

    static defaultProps = {
        isAdmin: false
    };

    state = {};

    onMenuItemPress = (route) => {
        return () => this.props.navigate(route);
    };

    onLogoutPress = async () => {
        await this.context.signOut();
        persist.purge(PERSIST_CONFIG);
        this.props.dispatch(purgeReduxStore({
            onSuccess: () => {
                this.props.dispatch(setAuthLoading(false));
                this.props.navigate("/");
            }
        }));
    };

    menuItemSelected = (routeGroup) => {
        return getRoutes(routeGroup).some((path) => matchPath(window.location.pathname, path));
    };

    renderDrawerItem = (Icon, text, routeGroup, onItemPress) => {
        return (
            <MenuItem
                onClick={ onItemPress }
                className={ styles.menu_button }
                selected={ this.menuItemSelected(routeGroup) }>
                { !!Icon ? <Icon /> : null }
                <ListItemText primary={ text } />
            </MenuItem>
        );
    };

    renderCharityUserDrawer = () => {
        return (
            <div className={ styles.toolbar_buttons }>
                { !this.props.showOnBoarding ? (
                    <Fragment>
                        { this.renderDrawerItem(null, "Dashboard", "/", this.onMenuItemPress(Routes.root)) }
                        { this.renderDrawerItem(null, "Account", Routes.Account.root, this.onMenuItemPress(Routes.Account.root)) }
                    </Fragment>
                ) : null }
                { this.renderDrawerItem(null, "Logout", null, this.onLogoutPress) }
            </div>
        );
    };

    onEditWebsitePress = () => {
        window.open("https://spend-my-super.sanity.studio/");
    };

    renderAdminUserDrawer = () => {
        return (
            <div className={ styles.toolbar_buttons }>
                { this.renderDrawerItem(null, "Users", Routes.Users.root, this.onMenuItemPress(Routes.Users.root)) }
                { this.renderDrawerItem(null, "Charities", Routes.Organisations.root, this.onMenuItemPress(Routes.Organisations.root)) }
                { this.renderDrawerItem(null, "Edit Website", null, this.onEditWebsitePress) }
                { this.renderDrawerItem(null, "Logout", null, this.onLogoutPress) }
            </div>
        );
    };

    render() {
        const { isAdmin } = this.props;
        return (
            <div className={ styles.root }>
                <CssBaseline />
                <AppBar className={ styles.app_bar } position={ "fixed" }>
                    <Toolbar className={ styles.toolbar }>
                        <img className={ styles.nav_logo } src={ Logo } alt={ "Share my Super" } />
                        { isAdmin ? this.renderAdminUserDrawer() : this.renderCharityUserDrawer() }
                    </Toolbar>
                </AppBar>
                { renderComponent(this.props.children) }
            </div>
        );
    }
}

export default connect((state) => {
    return {
        showOnBoarding: state.auth.showOnBoarding,
    };
})(withRouter(NavigationWrapper));
