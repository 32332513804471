import * as OrganisationAPI from "./api/organisations";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import { showError } from "./snackbars";

export const SET_STRIPE_ACCOUNT = "stripe:SET_STRIPE_ACCOUNT";

export const setStripeAccount = createAction(SET_STRIPE_ACCOUNT);

export const getStripeAccount = wrapper(OrganisationAPI.getStripeAccountAPI, (dispatch, stripeAccount) => {
    dispatch(setStripeAccount(stripeAccount));
}, (dispatch, error) => dispatch(showError(error.message)));