import React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from 'react-number-format';
import classnames from "classnames";
import omit from "lodash/omit";
import styles from "../textInput/Input.module.scss";

const NumberInput = React.forwardRef((props, ref) => {

    const className = classnames(styles.input, {
        [ styles.disabled ]: props.disabled
    }, props.className);

    const newProps = omit(props, [ ...Object.keys(NumberInput.defaultProps), "children", "field", "numberFormatProps" ]);

    return (
        <div className={ className }>
            { props.label && <label htmlFor={ props.id }>{ props.label }</label> }
            <div className={ styles.input_wrapper }>
                <NumericFormat value={ props.field.value }
                    { ...newProps }
                    { ...props.numberFormatProps }
                    { ...(props.field || {}) }
                    ref={ ref }
                    onValueChange={ (values) => {
                        props.form.setFieldValue(props.field.name, values.value);
                    } }
                />
            </div>
            { props.children }
            { props.form && props.form.touched[ props.field.name ] && props.form.errors[ props.field.name ] && <div>{ props.form.errors[ props.field.name ] }</div> }
        </div>
    );
});

NumberInput.propTypes = {
    numberFormatProps: PropTypes.object.isRequired,
    label: PropTypes.string
};

NumberInput.defaultProps = {
    label: ""
};

export default NumberInput;
