import * as ReportsAPI from "./api/reports";
import wrapper from "./helpers/wrapper";
import createAction from "./helpers/createAction";

export const SET_CHARITY_DONATION_REPORT = "organisationReports:SET_CHARITY_DONATION_REPORT";
export const SET_ALL_TIME_CHARITY_DONATION_REPORT = "organisationReports:SET_ALL_TIME_CHARITY_DONATION_REPORT";

export const setCharityDonationReport = createAction(SET_CHARITY_DONATION_REPORT);
export const setAllTimeCharityDonationReport = createAction(SET_ALL_TIME_CHARITY_DONATION_REPORT);

export const getDonationsReport = wrapper(ReportsAPI.getDonationsReportAPI, (dispatch, report, organisationId) => {

    const payload = {
        organisationId: organisationId,
        report: report,
    };

    dispatch(setCharityDonationReport(payload));
});

export const getAllTimeDonationsReport = wrapper(ReportsAPI.getAllTimeDonationsReportAPI, (dispatch, report, organisationId) => {

    const payload = {
        organisationId: organisationId,
        report: report,
    };

    dispatch(setAllTimeCharityDonationReport(payload));
});
