// Charities API calls

export function getCharitiesAPI() {
    return window.get("/v1/charities");
}

export function setHeroCharityAPI(organisationId) {
    return window.put(`/v1/charities/${organisationId}/hero`);
}

// Organisations API calls

export function getOrganisationsAPI() {
    return window.get("/v1/organisations");
}

export function createOrganisationAPI(payload) {
    return window.post("/v1/organisations", payload);
}

export function updateOrganisationAPI(organisationId, payload) {
    return window.put(`/v1/organisations/${organisationId}`, payload);
}

export function getCharityInfoAPI(organisationId) {
    return window.get(`/v1/organisations/${organisationId}/info`);
}

export function updateOrganisationStatusAPI(organisationId, organisation) {
    return window.put(`/v1/organisations/${organisationId}/status`, organisation);
}

export function getOrganisationUsersAPI(organisationId) {
    return window.get(`/v1/organisations/${organisationId}/users`);
}

export function getCharityReceiptInfoAPI(orgId) {
    return window.get(`/v1/organisations/${orgId}/receipt`);
}

export function updateCharityInfoAPI(organisationId, data) {
    return window.put(`/v1/organisations/${organisationId}/info`, data);
}

// Stripe API calls

export function updateStripeAccountAPI(organisationId, data) {
    return window.put(`v1/organisations/${organisationId}/stripe`, data);
}

export function getStripeAccountAPI(organisationId) {
    return window.get(`v1/organisations/${organisationId}/stripe`);
}

export function uploadIdDocumentAPI(organisationId, formData) {
    return window.post(`v1/organisations/${organisationId}/stripe/file`, formData);
}

export function updateStripePersonAPI(organisationId, data) {
    return window.put(`v1/organisations/${organisationId}/stripe/person`, data);
}
