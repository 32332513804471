import omit from "lodash/omit";
import React, { Component as ReactComponent } from "react";
import Component from "../components/widgets/component/Component";

export function renderComponent(ToRender, props = {}, options = { functionProps: {} }) {

    const newProps = omit({ ...props }, [ "children", "className" ]);

    const isReactClass = (ToRender instanceof Component.constructor) || (ToRender instanceof ReactComponent.constructor);

    if (typeof ToRender === "function") {
        const ToRenderReturn = ToRender({ ...newProps, ...(options.functionProps || {}) });
        return renderComponent(ToRenderReturn, props, options);
    }

    if (isReactClass) {
        return (
            <ToRender { ...newProps } />
        );
    }

    if (React.isValidElement(ToRender)) {
        return React.cloneElement(ToRender, newProps);
    }

    if (Array.isArray(ToRender)) {
        return ToRender.map((el, i) => {
            return renderComponent(el, { ...newProps, key: i }, options);
        });
    }

    return null;
}
