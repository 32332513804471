import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./redux/configureStore";
import Main from "./components/Main";
import SnackbarContainer from "./components/widgets/snackbar/SnackbarContainer";
import Loading from "./components/routes/loading/Loading";
import { ThemeProvider } from "@mui/system";
import { createTheme, CssBaseline, StyledEngineProvider } from "@mui/material";
import { FirebaseProvider } from "./context/firebase";

const theme = createTheme({
    typography: {
        useNextVariants: true,
        fontFamily: "\"Open Sans\", sans-serif"
    }
});

export const { store, persist } = configureStore();

export default function App() {
    return (
        <Provider store={ store }>
            <PersistGate loading={ <Loading /> } persistor={ persist }>
                <FirebaseProvider>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={ theme }>
                            <SnackbarContainer>
                                <CssBaseline />
                                <Suspense fallback={ <Loading /> }>
                                    <Router>
                                        <Routes>
                                            <Route path={ "*" } element={ <Main /> } />
                                        </Routes>
                                    </Router>
                                </Suspense>
                            </SnackbarContainer>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </FirebaseProvider>
            </PersistGate>
        </Provider>
    );
}
