import Dinero from "dinero.js";

export default function fromCents(amount = 0, options = {
    currency: null,
    pretty: false,
    format: "0",
}) {

    options.amount = Number(amount || 0);

    if (!!options.pretty) {
        options.format = "$0,0.00";
    }

    return Dinero(options).toFormat(options.format);
}
