/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-13.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import classnames from "classnames";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React from "react";
import styles from "./Label.module.scss";

function Label(props) {
    const className = classnames(styles.label, props.className);

    const newProps = omit({ ...props }, ["children"]);

    return (
        <span {...newProps} className={className}>{props.children}</span>
    );
}

Label.propTypes = {
    className: PropTypes.string
};

Label.defaultProps = {
    className: null
};

export default Label;