import { Component as ReactComponent } from "react";
import { showError } from "../../../redux/actions/snackbars";

const DEFAULT_OPTIONS = {
    loadingId: "loading",
    showErrorSnackbar: true,

    onSuccess: () => null,
    onLoadingSuccess: () => null,
    onError: () => null,
    onLoadingError: () => null,
};

export default class Component extends ReactComponent {

    dispatch = (dispatchFunc, dispatchPayload = {}, options = {}) => {

        if (!this.props.dispatch) {
            throw new Error("Component is not connected to redux.");
        }

        const { loadingId, onSuccess, onLoadingSuccess, onError, onLoadingError, showErrorSnackbar } = { ...DEFAULT_OPTIONS, ...options };

        const func = () => {
            this.props.dispatch(dispatchFunc({
                ...dispatchPayload,
                onSuccess: (data) => {
                    if (!!onSuccess) {
                        onSuccess(data);
                    }

                    this.stopLoading(loadingId, onLoadingSuccess);
                },
                onError: (err) => {

                    if (!!showErrorSnackbar) {
                        this.props.dispatch(showError(err.message));
                    }

                    if (!!onError) {
                        onError(err);
                    }

                    this.stopLoading(loadingId, onLoadingError);
                }
            }));
        };

        if (!this.state[ loadingId ]) {
            this.setState({
                [ loadingId ]: true
            }, func);
        } else {
            func();
        }
    };

    timeoutLoader = (loadingId = "loading") => {
        const timeout = setTimeout(() => {
            this.stopLoading(loadingId);
            clearTimeout(timeout);
        }, 500);
    };

    stopLoading = (loadingId = "loading", callback = () => null) => {
        this.setBool(loadingId, false, callback);
    };

    setBool = (id = null, bool = false, callback = () => null) => {
        if (!!id) {
            this.setState({
                [ id ]: bool
            }, callback);
        } else {
            callback();
        }
    };

    onRef = (name) => {
        return (ref) => {
            this[ name ] = ref;
        };
    };

    onChange = (name) => {
        return (text) => {
            this.setState({
                [ name ]: text,
            });
        };
    };

    onEventChange = (name) => {
        return (e) => {
            this.setState({
                [ name ]: e.target.value,
            });
        };
    };
}
