import React from "react";
import styles from "./Loading.module.scss";
import Spinner from "../../widgets/spinner/Spinner";

export default function Loading() {
    return (
        <div className={ styles.loading }>
            <Spinner large />
        </div>
    );
}
