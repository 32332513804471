import { Button as MatButton } from "@mui/material";
import classnames from "classnames";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React from "react";
import Spinner from "../spinner/Spinner";
import styles from "./Button.module.scss";

export default function Button(props) {

    const disabled = (props.loading || props.disabled);
    const className = classnames(styles.button, {
        [styles.disabled]: disabled,
        [styles.plain]: props.plain,
        [styles.blue]: props.blue
    }, props.className);

    const IconLeft = props.iconLeft;
    const IconRight = props.iconRight;

    const newProps = omit(props, Object.keys(Button.defaultProps));

    function onPress(e) {
        if (props.loading || props.disabled) {
            return;
        }

        props.onPress(e);
    }

    return (
        <MatButton {...newProps}
                   onClick={onPress}
                   className={className}
                   disabled={props.disabled}>
            <div>
                {!!props.loading && <Spinner small className={styles.spinner} />}
                {!props.loading && !!IconLeft && <IconLeft />}
                {!props.loading && <span>{props.children}</span>}
                {!props.loading && !!IconRight && <IconRight />}
            </div>
        </MatButton>
    );
}

Button.propTypes = {
    className: PropTypes.string,
    labelClassName: PropTypes.string,

    iconLeft: PropTypes.any,
    iconRight: PropTypes.any,

    onPress: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    round: PropTypes.bool,

    blue: PropTypes.bool,
    plain: PropTypes.bool
};

Button.defaultProps = {
    className: null,
    labelClassName: null,

    iconLeft: null,
    iconRight: null,

    onPress: () => null,
    disabled: false,
    loading: false,
    round: false,

    plain: false,
    blue: false,
    small: false
};


