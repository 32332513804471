import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./RadioInput.module.scss";

function RadioInput(props) {

    function onChange(e) {
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    }

    let name = props.name;

    if (props.field && props.field.name) {
        name = props.field.name;
    }

    return (
        <label className={styles.radio_input} htmlFor={name}>
            <input id={name}
                   type={"radio"}
                   checked={props.checked}
                   value={props.value}
                   onChange={onChange}/>
            {props.text}
        </label>
    );
}

RadioInput.propTypes = {
    name: PropTypes.string,
    text: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
};

RadioInput.defaultProps = {
    text: "",
    onChange: () => null,
    name: "",
    value: ""
};

export default connect()(RadioInput);