import React, { useState } from "react";
import { connect } from "react-redux";
import RadioInput from "./RadioInput";
import styles from "./RadioGroup.module.scss";

function RadioGroup(props) {
    const [selectedValue, setSelectedValue] = useState(props.initialValue);

    function onChange(value) {
        props.form.setValues({ ...props.form.values, [props.field.name]: value });
        setSelectedValue(value);
    }

    return (
        <div className={styles.radio_group}>
            <label>{props.text}</label>
            {React.Children.map(props.children, (child) => {
                return (
                    <RadioInput key={child.props.name}
                                {...child.props}
                                onChange={() => onChange(child.props.value)}
                                checked={selectedValue === child.props.value}
                    />
                );
            })}
        </div>
    );
}

export default connect()(RadioGroup);