import { SET_STRIPE_ACCOUNT } from "../actions/stripe";

const initialState = {};

export default function stripe(state = initialState, action) {
    if (action.type === SET_STRIPE_ACCOUNT) {
        return {
            ...state,
            stripeAccount: action.payload
        };
    }

    return {
        ...state
    };
};
