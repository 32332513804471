import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./Spinner.module.scss";

function Spinner(props) {

    // const newProps = omit({ ...props }, ["className", "containerClassName", "color", "large", "small", "vCenter", "hCenter"]);

    const className = classnames(styles.spinner, {
        [styles.spinner_large]: !!props.large,
        [styles.spinner_small]: !!props.small
    }, props.className);

    const containerClassName = classnames(styles.container, {
        [styles.vertical_center]: !!props.vCenter,
        [styles.horizontal_center]: !!props.hCenter
    });

    return (
        <div className={containerClassName}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
                <ellipse cx="12" cy="12" rx="10" ry="10" className={styles.spinner_ellipse}/>
            </svg>
        </div>
    );
}

Spinner.propTypes = {
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    color: PropTypes.string,
    large: PropTypes.bool,
    small: PropTypes.bool,
    vCenter: PropTypes.bool,
    hCenter: PropTypes.bool
};

Spinner.defaultProps = {
    className: null,
    containerClassName: null,
    color: "#9BA5A7",
    large: false,
    small: false,
    vCenter: false,
    hCenter: false
};

export default Spinner;
