
export default function getReceiptAPI(receiptParams, templateUrl) {
    const request = new Request(`https://app-ysn4rtgmfq-uc.a.run.app/pdf?url=${templateUrl}`, {
        method: "POST",
        body: JSON.stringify(receiptParams),
        headers: new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
        })
    });

    return fetch(request)
        .then(res => res.blob())
        .catch(err => console.log("receipt err", err));
}
