import { applyMiddleware, createStore } from "redux";
import { thunk } from 'redux-thunk';
import { persistStore } from "redux-persist";
import root from "./reducers/root";

export default function configureStore() {

    const store = createStore(
        root,
        applyMiddleware(
            thunk
        )
    );

    const persist = persistStore(store);

    return {
        store: store,
        persist: persist,
    };
}
