export function getUserAPI() {
    return window.get("/v1/me");
}

export function updateUserAPI(data) {
    return window.put("/v1/admin/me", data);
}

export function getUserOrganisationsAPI() {
    return window.get("/v1/me/organisations");
}

export function updateUserOrganisationAPI(organisationId, userOrganisationId, data) {
    return window.put(`/v1/organisations/${organisationId}/users/${userOrganisationId}`, data);
}

export function getUserIpAPI() {
    return window.get("/v1/me/ip");
}
