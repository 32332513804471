import * as OrganisationsAPI from "./api/organisations";
import wrapper from "./helpers/wrapper";
import createAction from "./helpers/createAction";
import { showSuccess } from "./snackbars";
import onDispatchError from "./helpers/onDispatchError";

export const SET_ORGANISATION = "organisations:SET_ORGANISATION";
export const SET_ORGANISATIONS = "organisations:SET_ORGANISATIONS";
export const SET_ORGANISATION_INFO = "organisations:SET_ORGANISATION_INFO";

export const setOrganisation = createAction(SET_ORGANISATION);
export const setOrganisations = createAction(SET_ORGANISATIONS);
export const setCharityInfo = createAction(SET_ORGANISATION_INFO);

export const getOrganisations = wrapper(OrganisationsAPI.getOrganisationsAPI, (dispatch, organisations) => {
    dispatch(setOrganisations(organisations));
}, onDispatchError);

export const createOrganisation = wrapper(OrganisationsAPI.createOrganisationAPI, (dispatch, organisation) => {
    dispatch(setOrganisation(organisation));
    dispatch(showSuccess("Organisation successfully created"));
}, onDispatchError);

export const updateOrganisationStatus = wrapper(OrganisationsAPI.updateOrganisationStatusAPI, (dispatch, organisation) => {
    dispatch(setOrganisation(organisation));
}, onDispatchError);

export const updateCharityInfo = wrapper(OrganisationsAPI.updateCharityInfoAPI, (dispatch, charityInfo) => {
    dispatch(setCharityInfo(charityInfo));
    dispatch(showSuccess("Organisation updated successfully"));
}, onDispatchError);

export const getCharityInfo = wrapper(OrganisationsAPI.getCharityInfoAPI, (dispatch, charityInfo) => {
    dispatch(setCharityInfo(charityInfo));
}, onDispatchError);

export const updateOrganisation = wrapper(OrganisationsAPI.updateOrganisationAPI, (dispatch, organisation) => {
    dispatch(setOrganisation(organisation));
}, onDispatchError);

export const updateStripeAccount = wrapper(OrganisationsAPI.updateStripeAccountAPI, () => {
}, onDispatchError);

export const uploadIdDocument = wrapper(OrganisationsAPI.uploadIdDocumentAPI, () => {
}, onDispatchError);

export const updateStripePerson = wrapper(OrganisationsAPI.updateStripePersonAPI, () => {
}, onDispatchError);

