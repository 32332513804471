import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, Formik } from "formik";
import styles from "./StripeDetails.module.scss";
import Button from "../../button/Button";
import Input from "../../input/textInput/Input";
import NumberInputField from "../../input/numberInput/NumberInputField";
import AddressInputField from "../../input/addressInput/AddressInputField";
import getOrganisationProps from "../../../../util/getOrganisationProps";
import { updateCharityInfo, updateStripeAccount, uploadIdDocument } from "../../../../redux/actions/organisations";
import Loading from "../../../routes/loading/Loading";
import { getUserIp } from "../../../../redux/actions/user";
import { getStripeAccount } from "../../../../redux/actions/stripe";
import isAdmin from "../../../../util/isAdmin";
import withRouter from "../../../../util/hooks/withRouter";

class StripeDetails extends Component {
    constructor(props) {
        super(props);

        if (props.stripeAccount) {
            props.dispatch(getUserIp({
                onSuccess: (ip) => {
                    this.setState({ ip: ip, loading: false });
                },
                onError: () => this.stopLoading()
            }));
        } else {
            props.dispatch(getStripeAccount({
                organisationId: props.organisationId,
                onSuccess: () => {
                    props.dispatch(getUserIp({
                        onSuccess: (ip) => {
                            this.setState({ ip: ip, loading: false });
                        },
                        onError: () => this.stopLoading()
                    }));
                },
                onError: () => this.stopLoading()
            }));
        }


        this.state = {
            loading: true,
            ip: null,
            editAccount: false
        };
    }

    onSubmit = (values) => {
        const address = {
            line1: values.line1,
            state: values.state,
            city: values.city,
            postalCode: values.postalCode,
            country: "NZ"
        };

        const charityInfoValues = {
            ...this.props.charityInfo,
            address: { ...address },
            businessDocument: values.businessDocument
        };

        const accNumber = values.accountNumber ? values.accountNumber.split("-").join("") : null;
        let routingNumber = accNumber ? accNumber.substring(0, 6) : "";
        let accountNumber = accNumber ? accNumber.substring(6) : "";

        if (process.env.REACT_APP_ENV === "development" || process.env.REACT_APP_ENV === "staging") {
            routingNumber = "110000";
            accountNumber = "0000000010";
        }

        const stripeInfoValues = {
            name: values.accountName,
            email: this.props.user.email,
            ip: this.state.ip,
            address: { ...address },
            bankAccount: {
                accountNumber: accountNumber,
                accountHolderName: values.accountName,
                routingNumber: routingNumber
            },
            businessDocument: values.businessDocument
        };

        if (this.props.charityInfo.gstNo) {
            stripeInfoValues.gstNo = this.props.charityInfo.gstNo;
        }

        if (this.props.charityInfo.websiteUrl) {
            stripeInfoValues.websiteUrl = this.props.charityInfo.websiteUrl;
        }

        if (this.props.charityInfo.phoneNumber) {
            stripeInfoValues.phoneNumber = this.props.charityInfo.phoneNumber;
        }

        this.setState({ loading: true });

        this.props.dispatch(updateCharityInfo({
            organisationId: this.props.organisationId,
            data: charityInfoValues,
            onSuccess: () => {
                if (values.accountName || values.accountNumber) {
                    this.props.dispatch(updateStripeAccount({
                        organisationId: this.props.organisationId,
                        data: stripeInfoValues,
                        onSuccess: () => {
                            if (this.props.nextPage) {
                                this.stopLoading();
                                this.props.nextPage();
                            } else {
                                this.props.dispatch(getStripeAccount({
                                    organisationId: this.props.organisationId,
                                    onSuccess: () => this.stopLoading(),
                                    onError: () => this.stopLoading()
                                }));
                            }
                        },
                        onError: () => this.setState({ loading: false })
                    }));
                } else {
                    this.stopLoading();
                    if (this.props.nextPage) {
                        this.props.nextPage();
                    }
                }
            },
            onError: () => this.stopLoading()
        }));
    };

    stopLoading = () => {
        this.setState({
            loading: false,
            editAccount: false,
        });
    };

    formatBankAccount = (bankAccount) => {
        if (bankAccount) {
            const bankNumber = bankAccount.routingNumber ? bankAccount.routingNumber.substring(0, 2) : "XX";
            const branchNumber = bankAccount.routingNumber ? bankAccount.routingNumber.substring(2) : "XXXX";
            const lastAccountDigits = bankAccount.last4 ? bankAccount.last4.substring(0, 2) : "XX";
            const lastDigits = bankAccount.last4 ? bankAccount.last4.substring(2) : "XX";
            return `${bankNumber}-${branchNumber}-XXXXX${lastAccountDigits}-${lastDigits}`;
        }
        return null;
    };

    validate = (values) => {
        const errors = {};

        if (!values.line1) {
            errors.line1 = "Required";
        }

        if (!values.state) {
            errors.state = "Required";
        }

        if (!values.city) {
            errors.city = "Required";
        }

        if (!values.postalCode) {
            errors.postalCode = "Required";
        }

        if (this.state.editAccount) {
            if (!values.accountNumber) {
                errors.accountNumber = "Required";
            }

            if (!values.accountName) {
                errors.accountName = "Required";
            }
        }

        return errors;
    };

    editAccountPress = () => {
        this.setState((prevState) => this.setState({ editAccount: !prevState.editAccount }));
    };

    renderBankAccountFields = () => {
        if (this.props.readOnly) {
            return this.renderReadOnlyFields();
        }

        if (!this.props.readOnly && (this.props.stripeAccount && this.props.stripeAccount.bankAccount)) {
            return this.renderReadOnlyFields();
        }

        return this.renderFormFields();
    };

    renderReadOnlyFields = () => {
        return (
            <div className={styles.form_content}>
                <div>
                    {this.state.editAccount ? (
                        <React.Fragment>
                            <label>Account Name</label>
                            <Field id={"accountName"}
                                   name={"accountName"}
                                   component={Input} />
                            <label>Account Number</label>
                            <NumberInputField name={"accountNumber"}
                                              numberFormatProps={{
                                                  format: "##-####-#######-##"
                                              }}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <label>Account Name</label>
                            <div>
                                {this.props.stripeAccount.bankAccount.accountHolderName ?
                                    this.props.stripeAccount.bankAccount.accountHolderName : "XXXXX"}
                            </div>
                            <label>Account Number</label>
                            <div>
                                {this.formatBankAccount(this.props.stripeAccount.bankAccount)}
                            </div>
                        </React.Fragment>
                    )}
                    {!this.props.readOnly && (
                        <Button onPress={this.editAccountPress} plain>
                            {`${this.state.editAccount ? "Cancel" : "Edit account"}`}
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    renderFormFields = () => (
        <div className={styles.form_content}>
            <Field id={"accountName"}
                   name={"accountName"}
                   label={"Account Name"}
                   component={Input}
            />
            <NumberInputField name={"accountNumber"}
                              label={"Account Number"}
                              numberFormatProps={{
                                  format: "##-####-#######-##"
                              }}
            />
        </div>
    );

    uploadIdDocumentFront = (formData, onSuccess, onError) => {
        formData.append("purpose", "additional_verification");

        this.props.dispatch(uploadIdDocument({
            organisationId: this.props.organisationId,
            formData: formData,
            onSuccess: onSuccess,
            onError: onError
        }));
    };

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return (
            <>
                <Formik initialValues={this.props.initialValues}
                        validate={this.validate}
                        enableReinitialize
                        onSubmit={this.onSubmit}>
                    {(props) => {
                        return (
                            <div className={styles.stripe_details}>
                                <div className={styles.form_container}>
                                    <div className={styles.form_content}>
                                        <h2>Stripe Details</h2>
                                        <AddressInputField name={"line1"}
                                                           initialAddress={this.props.initialValues.line1}
                                                           label={"Charity Registered Address"}
                                        />
                                        <Field id={"state"}
                                               name={"state"}
                                               label={"Suburb"}
                                               component={Input}
                                        />
                                        <Field id={"city"}
                                               name={"city"}
                                               label={"City"}
                                               component={Input}
                                        />
                                        <Field id={"postalCode"}
                                               name={"postalCode"}
                                               label={"Post Code"}
                                               component={Input}
                                        />
                                    </div>
                                </div>
                                <div className={styles.bank_container}>
                                    {this.renderBankAccountFields()}
                                </div>
                                <div className={styles.button}>
                                    <Button onPress={props.handleSubmit}
                                            disabled={this.state.loading || this.props.loadingImages}
                                            blue>
                                        {this.props.submitButtonText ? this.props.submitButtonText : "Next"}
                                    </Button>
                                </div>
                            </div>
                        );
                    }}
                </Formik>
            </>
        );
    }
}

export default withRouter(connect((state, props) => {
    const organisationProps = getOrganisationProps(state, props);
    const charityInfo = organisationProps.charityInfo;
    const address = charityInfo.address || {};
    return {
        initialValues: {
            line1: address.line1 ? address.line1 : "",
            state: address.state ? address.state : "",
            city: address.city ? address.city : "",
            postalCode: address.postalCode ? address.postalCode : "",
            accountNumber: "",
            accountName: ""
        },
        ...organisationProps,
        user: state.user,
        isAdmin: isAdmin(state.user),
        loadingImages: state.images.loadingImages
    };
})(StripeDetails));
