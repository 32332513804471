import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { uploadImages, uploadingImages } from "../../../../redux/actions/images";
import styles from "./FileInput.module.scss";
import Button from "../../button/Button";
import Spinner from "../../spinner/Spinner";

class FileInput extends React.Component {
    static propTypes = {
        allowMultiple: PropTypes.bool,
        label: PropTypes.string.isRequired,
        elementId: PropTypes.string.isRequired,
        imageResolution: PropTypes.exact({
            width: PropTypes.number,
            height: PropTypes.number
        }),
        previewId: PropTypes.string,
        loadManualPreview: PropTypes.bool,
        showDelete: PropTypes.bool
    };

    static defaultProps = {
        allowMultiple: false,
        imageResolution: null,
        previewId: null,
        loadManualPreview: false,
        showDelete: false
    };

    state = {
        loading: false,
        error: null
    };

    getImageDetails = async (file, fileReader) => {
        return new Promise(((resolve) => {
            fileReader.onerror = () => {
                fileReader.abort();
                resolve({ error: { message: "Error reading file" } });
            };

            const image = new Image();
            const response = {};

            fileReader.onload = (e) => {
                image.src = e.target.result;
            };

            fileReader.onloadend = () => {
                response.file = file;
                response.height = image.height;
                response.width = image.width;
                if (response.height !== this.props.imageResolution.height || response.width !== this.props.imageResolution.width) {
                    resolve({
                        error: {
                            message: `This image has to be ${this.props.imageResolution.width}(W)x${this.props.imageResolution.height}(H) pixels.`
                        }
                    });
                } else {
                    resolve(response);
                }
            };

            fileReader.readAsDataURL(file);
        }));
    };

    readFiles = async (files) => {
        const fileArray = [];

        for (let i = 0; i < files.length; i += 1) {
            let imageDetails = { file: files[i] };
            fileArray.push(imageDetails);
            // if (this.props.imageResolution) {
            //     const fileReader = new FileReader();
            //     /* eslint-disable */
            //     imageDetails = await this.getImageDetails(files[i], fileReader);
            // }
            //
            // if (imageDetails.error) {
            //     this.props.form.setFieldError(this.props.field.name, imageDetails.error.message);
            // } else {
            //
            // }
        }

        return fileArray;
    };

    onChange = async (e) => {
        if (e.currentTarget && e.currentTarget.files.length) {
            const files = e.currentTarget.files;
            const formData = new FormData();
            formData.isformdata = true;

            const parsedFiles = await this.readFiles(files);
            parsedFiles.map((file) => {
                formData.append("files", file.file, file.file.name);
                return null;
            });

            if (parsedFiles.length > 0) {
                this.setState({ loading: true });
                this.props.dispatch(uploadingImages(true));

                if (this.props.uploadFile) {
                    this.props.uploadFile(formData, (file) => {
                            if (this.props.loadManualPreview && this.props.previewId) {
                                const oFReader = new FileReader();
                                const elementId = this.props.previewId;
                                oFReader.readAsDataURL(document.getElementById(this.props.elementId).files[0]);
                                oFReader.onload = function (oFREvent) {
                                    const el =  document.getElementById(elementId);
                                    el.style.display = "inline-block";
                                    el.style.backgroundImage = `url(${oFREvent.target.result})`;
                                    el.style.backgroundSize = "cover";
                                    el.style.backgroundPosition = "center";
                                    el.style.backgroundRepeat = "no-repeat";
                                    el.style.height = "360px";
                                    el.style.width = "100%";
                                };
                            }

                            this.setState({ loading: false });
                            this.props.form.setFieldValue(this.props.field.name, file);
                            this.props.dispatch(uploadingImages(false));
                        },
                        () => {
                            this.setState({ loading: false });
                            this.props.dispatch(uploadingImages(false));
                        }
                    );
                } else {
                    this.props.dispatch(uploadImages({
                        files: formData,
                        onSuccess: (images) => {
                            this.setState({ loading: false });
                            this.props.form.setFieldValue(this.props.field.name, this.props.allowMultiple ? [...this.props.field.value, ...images] : images[0].url);
                            this.props.dispatch(uploadingImages(false));
                        },
                        onError: () => {
                            this.setState({ loading: false });
                            this.props.dispatch(uploadingImages(false));
                        }
                    }));
                }

            }
        }
    };

    onDeleteClick = (image) => {
        const imageIndex = this.props.field.value.indexOf(image);
        if (imageIndex > -1) {
            const newValue = JSON.parse(JSON.stringify(this.props.field.value));
            newValue.splice(imageIndex, 1);
            this.props.form.setFieldValue(this.props.field.name, newValue);
        }
    };

    renderPreview = () => {
        if (this.state.loading) {
            return <Spinner containerClassName={styles.spinner_container}/>;
        }

        if (this.props.field.value) {
            if (Array.isArray(this.props.field.value)) {
                return this.props.field.value.map((image) => {
                    if (this.props.showDelete) {
                        return (
                            <div key={`previewImage.${this.props.field.name}.${Math.random()}`}
                                 className={styles.preview_multiple}>
                                <div style={{ backgroundImage: `url("${image && image.url ? image.url : image}")` }}/>
                                <Button plain onPress={() => this.onDeleteClick(image)}>Delete</Button>
                            </div>
                        );
                    }
                    return (
                        <div style={{ backgroundImage: `url("${image.url}")` }}
                             key={`previewImage.${this.props.field.name}.${Math.random()}`}/>
                    );
                });
            }
            return <div className={styles.hero_edit_image}
                        style={{ backgroundImage: `url("${this.props.field.value}=s1500")` }}/>;
        }
        return null;
    };

    render() {
        return (
            <div className={styles.file_input}>
                <label htmlFor={this.props.elementId}>
                    {this.props.label && <div>{this.props.label}</div>}
                    <input id={this.props.elementId}
                           multiple={this.props.allowMultiple}
                           onChange={this.onChange}
                           type={"file"}
                           disabled={this.state.loading}
                           accept={"image/png,image/jpg,image/jpeg"}/>
                </label>
                {(this.state.loading || this.props.field.value) && !this.props.loadManualPreview && (
                    <div className={styles.preview_container}>
                        {this.renderPreview()}
                    </div>
                )}

                {this.props.loadManualPreview && this.props.previewId && (
                    <div id={this.props.previewId} className={styles.preview_container}>
                        {this.state.loading && (<Spinner containerClassName={styles.spinner_container}/>)}
                    </div>
                )}

                {this.props.form.errors && this.props.form.touched[this.props.field.name] && this.props.form.errors[this.props.field.name] &&
                <div>{this.props.form.errors[this.props.field.name]}</div>}
                {this.state.error && <div>{this.state.error}</div>}
            </div>
        );
    }
}

export default connect()(FileInput);