import React, { useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { connect } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import styles from "../textInput/Input.module.scss";

const AddressInput = React.forwardRef((props, ref) => {

    const [value, setValue] = useState(props.initialAddress);

    const onChange = (e) => setValue(e.target.value);

    const onPlaceSelected = (place) => {
        let address = "";
        let suburb = "";
        let city = "";
        let postCode = "";

        place.address_components.map((addressComponent) => {
            if (addressComponent.types.indexOf("street_number") > -1) {
                address = addressComponent.long_name;
            }

            if (addressComponent.types.indexOf("route") > -1) {
                address = `${address} ${addressComponent.long_name}`;
            }

            if (addressComponent.types.indexOf("sublocality") > -1) {
                suburb = addressComponent.long_name;
            }

            if (addressComponent.types.indexOf("locality") > -1) {
                city = addressComponent.long_name;
            }

            if (addressComponent.types.indexOf("postal_code") > -1) {
                postCode = addressComponent.long_name;
            }
            return null;
        });

        if (address !== "") {
            props.form.setFieldValue("line1", address);
        }

        if (suburb !== "") {
            props.form.setFieldValue("state", suburb);
        }

        if (city !== "") {
            props.form.setFieldValue("city", city);
        }

        if (postCode !== "") {
            props.form.setFieldValue("postalCode", postCode);
        }

        setValue(address);
    };

    const className = classnames(styles.input, {
        [styles.disabled]: props.disabled
    }, props.className);

    return (
        <div className={className}>
            {props.label && <label htmlFor={props.id}>{props.label}</label>}
            <div className={styles.input_wrapper}>
                <Autocomplete ref={ref}
                              onPlaceSelected={onPlaceSelected}
                              value={value}
                              onChange={onChange}
                              options={{
                                  componentRestriction: { country: "nz" },
                                  types: ["address"]
                              }}
                />
            </div>
            {props.form && props.form.touched[props.field.name] && props.form.errors[props.field.name] &&
            <div>{props.form.errors[props.field.name]}</div>}
        </div>
    );
});

AddressInput.propTypes = {
    label: PropTypes.string
};

AddressInput.defaultProps = {
    label: ""
};

export default connect()(AddressInput);
