import * as Types from "../actions/users";

const initialState = {};

export default function users(state = initialState, action = { type: null, payload: null }) {
    switch (action.type) {

        case Types.SET_USERS: {

            const newState = { ...state };
            (action.payload || []).forEach((user) => {
                newState[ user.id ] = user;
            });

            return newState;
        }

        case Types.SET_USER: {
            const user = (action.payload || {});
            return {
                ...state,
                [ user.id ]: user,
            };
        }
        default: {
            return state;
        }
    }
}
