import * as Types from "../actions/transactions";

const initialState = {
    users: {},
    organisations: {},
    transactions: {},
    eofy: {}
};

export default function transactions(state = initialState, action = { type: null, payload: null }) {
    switch (action.type) {


        case Types.SET_USER_TRANSACTIONS: {
            const data = action.payload.data;

            const items = {};
            data.items.map((transaction) => {
                items[ transaction.id ] = transaction;
                return null;
            });

            const newData = {
                ...data,
                items: items,
            };

            const oldItems = (state.transactions[ action.payload.userId ] || {}).items || {};

            return {
                ...state,
                transactions: {
                    [ action.payload.userId ]: {
                        nextCursor: newData.nextCursor,
                        items: { ...oldItems, ...newData.items }
                    }
                }
            };
        }

        case Types.SET_USER_EOFY_RECEIPTS: {
            const { userId, data } = action.payload;
            const items = {};
            data.items.forEach((eofy) => {
                items[ eofy.id ] = eofy;
            });

            const oldItems = (state.eofy[ action.payload.userId ] || {}).items || {};
            const newData = {
                ...data,
                items: items,
            };

            return {
                ...state,
                eofy: {
                    [ userId ]: {
                        nextCursor: newData.nextCursor,
                        items: { ...oldItems, ...newData.items }
                    }
                }
            };

        }

        case Types.SET_ORGANISATION_TRANSACTIONS: {

            const payload = action.payload || { organisationId: null, data: null };
            const organisationId = payload.organisationId;
            const data = payload.data;

            const organisationState = (state.organisations[ organisationId ] || {});

            const page = Object.keys(organisationState).length;


            const items = {};
            data.items.map((transaction) => {
                items[ transaction.id ] = transaction;
                return null;
            });

            const newData = {
                ...data,
                items: items,
            };

            return {
                ...state,
                organisations: {
                    ...state.organisations,
                    [ organisationId ]: {
                        ...organisationState,
                        [ page ]: newData
                    },
                },
            };
        }

        case Types.CLEAR_ORGANISATION_TRANSACTIONS: {

            const organisationId = action.payload;

            return {
                ...state,
                organisations: {
                    ...state.organisations,
                    [ organisationId ]: {},
                },
            };
        }

        default: {
            return state;
        }
    }
}
