import React, {Component} from "react";
import {connect} from "react-redux";
import _isEmpty from "lodash/isEmpty";
import moment from "moment";
import _values from "lodash/values";
import styles from "./DonationHistory.module.scss";
import {PER_PAGE_OPTIONS} from "../../../util/transactionConstants";
import Button from "../button/Button";
import ComponentLoader from "../componentLoader/ComponentLoader";
import fromCents from "../../../util/currencies/fromCents";
import {getCharityReceiptInfoAPI} from "../../../redux/actions/api/organisations";
import {showError, showSuccess} from "../../../redux/actions/snackbars";
import {getUserEOFYReceipts, getUserTransactions} from "../../../redux/actions/transactions";
import getReceiptAPI from "../../../redux/actions/api/receipt";
import getUserProps from "../../../util/getUserProps";
import { changeEofyStatus } from "../../../redux/actions/users";
import withRouter from "../../../util/hooks/withRouter";

class DonationHistory extends Component {

    state = {
        loading: false,
        loadingReceipt: null,
        loadingPage: false,
        loadingEOFY: false,
        loadingEofyStatusChange: false,
        page: 0,
        limit: PER_PAGE_OPTIONS[100].value,
        from: moment().subtract(1, "years"),
        to: moment(),
    };

    componentDidMount() {
        if (_isEmpty(((this.props.transactions[this.props.params.userId] || {})[0] || {}).items || [])) {
            this.setState({ loading: true }, this.getTransactions);
        }

        if (_isEmpty(((this.props.eofy[this.props.params.userId] || {})[0] || {}).items || [])) {
            this.setState({ loadingEOFY: true }, this.getEOFYReceipts);
        }
    }

    getEOFYReceipts = (cursor) => {
        this.props.dispatch(getUserEOFYReceipts({
            userId: this.props.params.userId,
            cursor,
            onSuccess: () => {
                this.setState({loadingEOFY: false});
            }
        }));
    };

    onTransactionParamsChange = (newParams = {}) => {
        const params = {
            page: 0,
            limit: this.state.limit,
            from: this.state.from,
            to: this.state.to,
            ...newParams
        };

        // this.props.dispatch(clearTransactions());
        this.setState({...params}, this.getTransactions);
    };

    onLimitChange = (e, limit) => {
        this.onTransactionParamsChange({limit: limit});
    };

    onTransactionStartChange = (startDate) => {
        this.onTransactionParamsChange({from: startDate});
    };

    onTransactionEndChange = (startDate, endDate) => {
        this.onTransactionParamsChange({to: endDate});

    };

    getTransactions = (cursor = null) => {
        const params = {};

        if (!!cursor) {
            params.cursor = cursor;
        }

        this.props.dispatch(getUserTransactions({
            userId: this.props.params.userId,
            params,
            onSuccess: () => {
                this.setState({
                    loading: false,
                    loadingPage: false
                });
            },
            onError: (error) => {
                this.setState({
                    loading: false,
                    loadingPage: false
                });
                this.props.dispatch(showError(error.message));
            }
        }));
    };

    onNextPagePress = () => {
        this.setState({
            loadingPage: true
        }, () => {
            this.getTransactions(this.props.transactions[this.props.params.userId].nextCursor);
        });
    };

    onPreviousPagePress = () => {
        const currentPage = this.state.page;
        const previousPage = currentPage - 1;

        this.setState({
            page: previousPage
        });
    };

    onDownloadReceiptPress = async (transaction) => {
        this.setState({loadingReceipt: transaction.id});
        try {
            const charityInfo = await getCharityReceiptInfoAPI(transaction.charity);
            const {
                name,
                address,
                charityNo,
                gstNo,
                receiptPersonName,
                receiptPersonTitle,
                receiptSignatureUrl,
                websiteUrl,
                logoUrl
            } = charityInfo;
            let addressString = "";
            if (address.line1) {
                addressString += address.line1;
            }
            if (address.line2) {
                addressString += `, ${address.line2}`;
            }
            if (address.state) {
                addressString += `, ${address.state}`;
            }
            if (address.city) {
                addressString += `, ${address.city}`;
            }
            if (address.Country) {
                addressString += `, ${address.Country}`;
            }
            if (address.postalCode) {
                addressString += ` ${address.postalCode}`;
            }

            const receiptParams = {
                receiptDate: moment().format("DD-MM-YY"),
                donationDate: moment(transaction.created).format("DD-MM-YY"),
                donationTotal: fromCents(transaction.amount, {
                    currency: transaction.currency,
                    pretty: true
                }),
                receiptNumber: transaction.id,
                donorFullName: (this.props.user || {}).name,
                donorId: (this.props.user || {}).stripeCustomerId,
                charityName: name,
                charityAddress: addressString,
                charityNumber: charityNo,
                gstNumber: gstNo,
                charityRepName: receiptPersonName,
                charityRepTitle: receiptPersonTitle,
                signatureUrl: receiptSignatureUrl,
                websiteUrl: websiteUrl,
                logoUrl: logoUrl,
            };

            const receiptBlob = await getReceiptAPI(receiptParams, `${process.env.REACT_APP_SITE_URL}/donation-receipt`);
            const url = window.URL.createObjectURL(receiptBlob);
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            link.setAttribute("download", "donation-receipt.pdf");
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);

        } catch (err) {
            console.log("err", err);
        }
        this.setState({loadingReceipt: null});
    };

    renderTransaction = (transaction) => {
        const {amount, charity, created, currency, id} = transaction;
        const charityName = (this.props.charities[charity] || {}).name;

        return (
            <tr role="row" key={id}>
                <td role="cell">{created && moment(created).format("DD/MM/YY HH:mm")}</td>
                <td role="cell">{id}</td>
                <td role="cell">{charityName}</td>
                <td role="cell">{fromCents(amount, {currency, pretty: true})}</td>
                <td role="cell">
                    <Button small
                            plain
                            loading={this.state.loadingReceipt === transaction.id}
                            onPress={() => this.onDownloadReceiptPress(transaction)}>
                        Download receipt
                    </Button>
                </td>
            </tr>
        );
    };

    renderEOFYReceipt = (eofyReceipt) => {
        const {amount, charity, yearEnding, id, receiptUrl} = eofyReceipt;
        const charityName = (this.props.charities[charity] || {}).name;

        return (
            <tr role="row" key={id}>
                <td role="cell">{yearEnding}</td>
                <td role="cell">{charityName}</td>
                <td role="cell">{fromCents(amount, {currecy: "nzd", pretty: true})}</td>
                <td role="cell">
                    <Button small
                            plain
                            onPress={() => window.open(receiptUrl)}>
                        Download receipt
                    </Button>
                </td>
            </tr>
        );
    };

    renderEOFYReceipts = () => {
        if (this.state.loadingEOFY && _isEmpty(((this.props.eofy[this.props.params.userId] || {})[0] || {}).items || {})) {
            return <tr><td><div className={styles.loader_wrapper}><ComponentLoader /></div></td></tr>;
        }

        if (!_isEmpty((this.props.eofy[this.props.params.userId] || {}).items || {})) {
            const transactionsList = ((this.props.eofy[this.props.params.userId]).items || {});
            return _values(transactionsList).map(this.renderEOFYReceipt);
        }

        return (
            <tr className={styles.transaction_placeholder}>
                <td>No End of Financial Year receipts available yet</td>
            </tr>
        );
    };

    renderTransactions = () => {
        if (this.state.loading) {
            return <tr><td><div className={styles.loader_wrapper}><ComponentLoader /></div></td></tr>;
        }

        if (!_isEmpty(this.props.transactions[this.props.params.userId])) {
            const transactionsList = ((this.props.transactions[this.props.params.userId]).items || {});
            return _values(transactionsList).map(this.renderTransaction);
        }

        return (
            <tr className={styles.transaction_placeholder}>
                <td>No transactions yet</td>
            </tr>
        );
    };

    onChangeEofyStatusClick = () => {
        this.setState({loadingEofyStatusChange: true});
        this.props.dispatch(changeEofyStatus({
            userId: this.props.selectedUser.id,
            status: !this.props.selectedUser.stopEofyReceipt,
            onSuccess: () => {
                this.setState({loadingEofyStatusChange: false});
                this.props.dispatch(showSuccess(`EOFY receipts ${this.props.selectedUser.stopEofyReceipt ? "disabled" : "enabled"}.`))
            }
        }));
    };

    renderTransactionTable = () => {
        return (
            <div className={styles.transaction_history_content}>
                <div className={styles.section}>
                    <div className={styles.header}>
                        <h3>Donation history</h3>
                        <Button onPress={this.onChangeEofyStatusClick}
                                disabled={this.state.loadingEofyStatusChange}
                                loading={this.state.loadingEofyStatusChange}>
                            {this.props.selectedUser.stopEofyReceipt ? "Send EOFY Receipts" : "Stop EOFY Receipts"}
                        </Button>
                    </div>
                    <div className={styles.section_header}>EOFY Receipts</div>
                    {this.props.selectedUser.stopEofyReceipt &&
                    <div className={styles.no_receipts}>This user no longer receives End of Financial Year receipts.</div>}
                    <table className={styles.transaction_history_table}>
                        <thead>
                        <tr role="row">
                            <th role={"columnheader"}>Year Ending</th>
                            <th role={"columnheader"}>Charity</th>
                            <th role={"columnheader"}>Amount</th>
                            <th role={"columnheader"}>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderEOFYReceipts()}
                        </tbody>
                    </table>
                    {(this.props.eofy[this.props.params.userId] || {}).nextCursor && (
                        <Button plain
                                onPress={() => this.getEOFYReceipts((this.props.eofy[this.props.params.userId] || {}).nextCursor)}
                                loading={this.state.loadingPage}>Load more</Button>
                    )}
                </div>
                <div className={styles.section}>
                    <div className={styles.section_header}>Charity Donations</div>
                    <table className={styles.transaction_history_table}
                           role="table">
                        <thead>
                        <tr role="row">
                            <th role="columnheader">Date</th>
                            <th role="columnheader">Transaction ID</th>
                            <th role="columnheader">Charity</th>
                            <th role="columnheader">Amount</th>
                            <th role={"columnheader"}>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderTransactions()}
                        </tbody>
                    </table>
                    {(this.props.transactions[this.props.params.userId] || {}).nextCursor && (
                        <Button plain onPress={this.onNextPagePress} loading={this.state.loadingPage}>Load more</Button>
                    )}
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className={styles.transaction_history}>
                {this.state.loading && !this.props.charities ? <ComponentLoader/> : this.renderTransactionTable()}
            </div>
        );
    }
}

export default withRouter(connect((state, props) => {
    return {
        charities: state.organisations,
        transactions: state.transactions.transactions,
        eofy: state.transactions.eofy,
        ...getUserProps(state, props)
    };
})(DonationHistory));
