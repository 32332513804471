export function searchUsersAPI(params) {
    return window.get("/v1/users", params);
}

export function getUserAdminAPI(userId) {
    return window.get(`/v1/users/${userId}`);
}

export function updateUserAdminAPI(userId, data, params) {
    return window.put(`/v1/users/${userId}`, data, params);
}

export function changeEofyStatus(userId, status) {
    return window.put(`/v1/admin/users/${userId}/eofy/${status}`);
}
