import wrapper from "./helpers/wrapper";
import * as UsersAPI from "./api/users";
import createAction from "./helpers/createAction";


export const SET_USERS = "users:SET_USERS";
export const SET_USER = "users:SET_USER";

export const setUsers = createAction(SET_USERS);
export const setUser = createAction(SET_USER);

export const searchUsers = wrapper(UsersAPI.searchUsersAPI, (dispatch, data) => {
    dispatch(setUsers(data.items));
});

export const getUserAdmin = wrapper(UsersAPI.getUserAdminAPI, (dispatch, user) => {
    dispatch(setUser(user));
});

export const updateUserAdmin = wrapper(UsersAPI.updateUserAdminAPI, (dispatch, user) => {
    dispatch(setUser(user));
});

export const changeEofyStatus = wrapper(UsersAPI.changeEofyStatus, (dispatch, user) => {
    dispatch(setUser(user));
});

