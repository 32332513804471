import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import * as TransactionsAPI from "./api/transactions";

export const SET_USER_TRANSACTIONS = "transactions:SET_USER_TRANSACTIONS";
export const SET_ORGANISATION_TRANSACTIONS = "transactions:SET_ORGANISATION_TRANSACTIONS";
export const CLEAR_ORGANISATION_TRANSACTIONS = "transactions:CLEAR_ORGANISATION_TRANSACTIONS";
export const SET_USER_EOFY_RECEIPTS = "transactions:SET_USER_EOFY_RECEIPTS";

export const setUserTransactions = createAction(SET_USER_TRANSACTIONS);
export const setOrganisationTransactions = createAction(SET_ORGANISATION_TRANSACTIONS);
export const clearOrganisationTransactions = createAction(CLEAR_ORGANISATION_TRANSACTIONS);
export const setUserEOFYReceipts = createAction(SET_USER_EOFY_RECEIPTS);

export const getUserTransactions = wrapper(TransactionsAPI.getUserTransactionsAPI, (dispatch, data, userId) => {

    const payload = {
        userId: userId,
        data: data,
    };

    dispatch(setUserTransactions(payload));
});

export const getOrganisationTransactions = wrapper(TransactionsAPI.getOrganisationTransactionsAPI, (dispatch, data, organisationId) => {

    const payload = {
        organisationId: organisationId,
        data: data,
    };

    dispatch(setOrganisationTransactions(payload));

});

export const getUserEOFYReceipts = wrapper(TransactionsAPI.getUserEOFYReceipts, (dispatch, data, userId) => {
    const payload = {
        userId,
        data
    };

    dispatch(setUserEOFYReceipts(payload));
});
