export function getRoutes(routes = {}) {

    if (!routes) {
        return [];
    }

    if (typeof routes === "string") {
        return [ routes ];
    }

    const paths = [];

    Object.keys(routes).map((key) => {
        const path = routes[ key ];

        if (typeof path === "object") {
            paths.push(...getRoutes(path));
        } else {
            paths.push(path);
        }

        return null;
    });

    return paths;
}