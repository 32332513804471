import isAdmin from "./isAdmin";

export default function getUserProps(state, props) {
    const params = props.params || {};

    let userId = !!params.userId ? params.userId : null;
    let user = null;

    const stateUser = state.user;
    if (!!stateUser) {
        if (isAdmin(stateUser)) {
            if (!!userId) {
                user = state.users[userId];
            } else {
                userId = stateUser.id;
                user = stateUser;
            }
        } else {
            userId = stateUser.id;
            user = stateUser;
        }
    }

    return {
        userId: userId,
        user: user,
        selectedUser: state.users[userId]
    };
}
