import React, { Component, Suspense } from "react";
import { Route, Routes as RRoutes } from "react-router-dom";
import { connect } from "react-redux";
import Loading from "./routes/loading/Loading";
import isAdmin from "../util/isAdmin";
import Routes from "../util/routes";
import NavigationWrapper from "./widgets/navigationWrapper/NavigationWrapper";
import Login from "./routes/login/Login";
import getOrganisationProps from "../util/getOrganisationProps";
import OnBoardingDialog from "./routes/login/onBoardingDialog/OnBoardingDialog";
import { FirebaseContext } from "../context/firebase";
import AccountDetails from "./widgets/forms/accountDetails/AccountDetails";
import DonationHistory from "./widgets/donationHistory/DonationHistory";
import CharityDetails from "./widgets/forms/charityDetails/CharityDetails";
import StripeDetails from "./widgets/forms/stripeDetails/StripeDetails";
import DonationReceipts from "./widgets/forms/donationReceipts/DonationReceipts";

const Organisation = React.lazy(() => import("./routes/organisation/Organisation"));
const Organisations = React.lazy(() => import("./routes/organisations/Organisations"));
const User = React.lazy(() => import("./routes/user/User"));
const Users = React.lazy(() => import("./routes/users/Users"));
const AdminDashboard = React.lazy(() => import("./routes/dashboard/adminDashboard/AdminDashboard"));

const Account = React.lazy(() => import("./routes/account/Account"));
const OrganisationTransactions = React.lazy(() => import("./routes/organisation/routes/organisationTransactions/OrganisationTransactions"));
const OrganisationDashboard = React.lazy(() => import("./routes/organisation/routes/organisationDashboard/OrganisationDashboard"));
const OnBoarding = React.lazy(() => import("./routes/onBoarding/OnBoarding"));

class Main extends Component {
    static contextType = FirebaseContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    renderAdminMainApp = () => {
        return (
            <>
                <Route path={ "/organisations/:organisationId/*" } element={ <Organisation { ...this.props } /> }>
                    <Route index element={ <OrganisationDashboard { ...this.props } /> } />
                    <Route path={ "details" } element={ <CharityDetails { ...this.props } /> } />
                    <Route path={ "transactions" } element={ <OrganisationTransactions { ...this.props } /> } />
                </Route>
                <Route path={ Routes.Organisations.root } element={ <Organisations { ...this.props } /> } />
                <Route path={ "users/:userId/*" } element={ <User { ...this.props } /> }>
                    <Route index element={ <AccountDetails { ...this.props } /> } />
                    <Route path={ "donations" } element={ <DonationHistory { ...this.props } /> } />
                </Route>
                <Route path={ Routes.Users.root } element={ <Users { ...this.props } /> } />
                <Route exact path={ Routes.root } element={ <AdminDashboard { ...this.props } /> } />
            </>
        );
    };

    renderMainApp = () => {

        if (this.props.isAdmin) {
            return this.renderAdminMainApp();
        }

        if (this.props.showOnBoarding) {
            return (
                <Route path={ Routes.Organisations.OnBoarding } element={ <OnBoarding { ...this.props } /> } />
            );
        }

        return (
            <>
                <Route path={ "account/*" } element={ <Account { ...this.props } /> }>
                    <Route index element={ <AccountDetails { ...this.props } /> } />
                    <Route path={ "charity" } element={ <CharityDetails { ...this.props } /> } />
                    <Route path={ "bank-details" } element={ <StripeDetails { ...this.props } /> } />
                    <Route path={ "donation-receipts" } element={ <DonationReceipts { ...this.props } /> } />
                </Route>
                <Route exact path={ Routes.root } element={ <OrganisationDashboard { ...this.props } /> } />
            </>
        );
    };

    render() {
        if (this.props.authLoading || (!this.props.isAdmin && this.props.user && (!this.props.organisationId || !this.props.charityInfo))) {
            return (
                <Loading />
            );
        }

        if (!this.props.isLoggedIn) {
            return (
                <Login />
            );
        }

        return (
            <NavigationWrapper isAdmin={ this.props.isAdmin }>
                <Suspense fallback={ <Loading /> }>
                    <RRoutes>
                        { this.renderMainApp() }
                    </RRoutes>
                </Suspense>
                <OnBoardingDialog />
            </NavigationWrapper>
        );
    }
}

export default connect((state, props) => {

    const user = state.user;

    return {
        user: user,
        isLoggedIn: !!user,
        isAdmin: isAdmin(user),
        showOnBoarding: state.auth.showOnBoarding,

        ...getOrganisationProps(state, props),
    };
})(Main);
