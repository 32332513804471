import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { removeSnackbar } from "../../../redux/actions/snackbars";
import Snackbar from "./Snackbar";
import styles from "./SnackbarContainer.module.scss";

class SnackbarContainer extends Component {

    onClose = (config) => {
        this.props.dispatch(removeSnackbar(config.id));
    };

    renderSnackbars = () => {
        return this.props.snackbars.map(this.renderSnackbar);
    };

    renderSnackbar = (config) => {
        return (
            <Snackbar key={ config.id }
                snackbarShown
                onClose={ this.onClose }
                config={ config } />
        );
    };

    render() {
        return (
            <Fragment>
                { this.props.children }
                <div className={ styles.snackbar_container }>
                    { this.renderSnackbars() }
                </div>
            </Fragment>
        );
    }
}

export default connect((state) => {
    return {
        snackbars: state.snackbars
    };
})(SnackbarContainer);
