import * as Types from "../actions/settings";

const initialState = {
    organisation: null,
    authLoading: false
};

export default function settings(state = initialState, action = { type: null, payload: null }) {
    switch (action.type) {

        case Types.SET_ACTIVE_ORGANISATION: {
            const orgId = action.payload;

            if (!orgId) {
                return state;
            }

            return {
                ...state,
                organisation: orgId
            };
        }
        case Types.SET_AUTH_LOADING: {
            return {
                ...state,
                authLoading: action.payload
            };
        }
        default: {
            return state;
        }
    }
}
