import createAction from "./helpers/createAction";

export const ADD_SNACKBAR = "snackbars:ADD_SNACKBAR";
export const REMOVE_SNACKBAR = "snackbars:REMOVE_SNACKBAR";

export const addSnackbar = createAction(ADD_SNACKBAR);
export const removeSnackbar = createAction(REMOVE_SNACKBAR);

export function showNotification(text, extraConfig = {}) {
    return (dispatch) => {

        const config = {
            text: text,
            id: new Date().getTime(),
            ...extraConfig
        };

        dispatch(addSnackbar(config));
    };
}

export function showSuccess(text, extraConfig = {}) {
    return (dispatch) => {
        dispatch(showNotification(text, { success: true, ...extraConfig }));
    };
}

export function showWarning(text, extraConfig = {}) {
    return (dispatch) => {
        dispatch(showNotification(text, { warning: true, ...extraConfig }));
    };
}

export function showError(text, extraConfig = {}) {
    return (dispatch) => {
        dispatch(showNotification(text, { error: true, ...extraConfig }));
    };
}
