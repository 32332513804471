import React from "react";
import { Field, Formik } from "formik";
import { connect } from "react-redux";
import { object, string } from "yup";
import Button from "../../button/Button";
import Input from "../../input/textInput/Input";
import FileInput from "../../input/fileInput/FileInput";
import Loading from "../../../routes/loading/Loading";
import getOrganisationProps from "../../../../util/getOrganisationProps";
import { updateCharityInfo } from "../../../../redux/actions/organisations";
import styles from "./DonationReceipts.module.scss";
import Component from "../../component/Component";
import withRouter from "../../../../util/hooks/withRouter";

const schema = object().shape({
    receiptPersonName: string(),
    receiptPersonTitle: string(),
    receiptSignatureUrl: string()
});

class DonationReceipts extends Component {

    state = {
        loading: false
    };

    onSubmit = (values) => {

        const data = {
            ...this.props.charityInfo,
            ...values
        };

        const options = {
            onSuccess: () => {
                if (this.props.nextPage) {
                    this.props.nextPage();
                }
            },
        };

        this.dispatch(updateCharityInfo, {
            organisationId: this.props.organisationId,
            data: data,
        }, options);

    };

    validate = (values) => {
        const errors = {};

        if (!values.receiptPersonName) {
            errors.receiptPersonName = "Required";
        }

        if (!values.receiptPersonTitle) {
            errors.receiptPersonTitle = "Required";
        }

        if (!values.receiptSignatureUrl) {
            errors.receiptSignatureUrl = "Required";
        }

        return errors;
    };

    renderForm = (formProps) => {
        return (
            <div className={styles.donation_details}>
                <div className={styles.form_container}>
                    <div className={styles.form_content}>
                        <h2>Donation Receipts</h2>
                        <p>
                            Please provide the details of a senior member to display at the bottom of the Donation
                            Receipt.
                            Please ensure the details supplied follow Charity Guidelines.
                        </p>
                        <Field id={"receiptPersonName"}
                               name={"receiptPersonName"}
                               label={"Full Name of Executive Officer (or similar title)"}
                               component={Input}
                        />
                        <Field id={"receiptPersonTitle"}
                               name={"receiptPersonTitle"}
                               label={"Title (e.g Executive Officer)"}
                               component={Input}
                        />
                        <Field elementId={"receiptSignatureUrl"}
                               name={"receiptSignatureUrl"}
                               label={"Signature of person mentioned - 90px(W) x 45px(H)"}
                               component={FileInput}
                               imageResolution={{ width: 90, height: 45 }}
                               {...formProps}
                        />
                    </div>
                </div>
                <div className={styles.button}>
                    <Button onPress={formProps.handleSubmit}
                            blue
                            disabled={this.props.loadingImages || !formProps.isValid}>
                        {this.props.submitButtonText ? this.props.submitButtonText : "Next"}
                    </Button>
                </div>
            </div>
        );
    };

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return (
            <Formik initialValues={this.props.initialValues}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={this.onSubmit}
                    validate={this.validate}
            >
                {(props) => this.renderForm(props)}
            </Formik>
        );
    }
}

export default withRouter(connect((state, props) => {

    const organisationProps = getOrganisationProps(state, props);
    const charityInfo = organisationProps.charityInfo;

    return {
        initialValues: {
            receiptPersonName: charityInfo.receiptPersonName ? charityInfo.receiptPersonName : "",
            receiptPersonTitle: charityInfo.receiptPersonTitle ? charityInfo.receiptPersonTitle : "",
            receiptSignatureUrl: charityInfo.receiptSignatureUrl ? charityInfo.receiptSignatureUrl : "",
        },
        ...organisationProps,
        loadingImages: state.images.loadingImages
    };
})(DonationReceipts));
