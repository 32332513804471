import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Field, Formik } from "formik";
import Button from "../../button/Button";
import Input from "../../input/textInput/Input";
import { updateUser } from "../../../../redux/actions/user";
import NumberInputField from "../../input/numberInput/NumberInputField";
import styles from "./AccountDetails.module.scss";
import getUserProps from "../../../../util/getUserProps";
import isAdmin from "../../../../util/isAdmin";
import Component from "../../component/Component";
import { updateUserAdmin } from "../../../../redux/actions/users";
import Loading from "../../../routes/loading/Loading";
import FileInput from "../../input/fileInput/FileInput";
import { updateStripePerson, uploadIdDocument } from "../../../../redux/actions/organisations";
import RadioInput from "../../input/radioInput/RadioInput";
import RadioGroup from "../../input/radioInput/RadioGroup";
import AddressInputField from "../../input/addressInput/AddressInputField";
import Chip from "../../chip/Chip";
import withRouter from "../../../../util/hooks/withRouter";

class AccountDetails extends Component {

    state = {
        loading: false
    };

    onSubmit = (values) => {
        const dobArr = values.dateOfBirth.split("/");
        let dob = null;

        if (dobArr.length === 3) {
            dob = {
                day: Number(dobArr[ 0 ]),
                month: Number(dobArr[ 1 ]),
                year: Number(dobArr[ 2 ])
            };
        }

        const address = {
            line1: values.line1,
            city: values.city,
            state: values.state,
            postalCode: values.postalCode,
            country: "NZ"
        };

        const data = {
            ...this.props.user,
            ...values,
            name: `${values.firstName} ${values.lastName}`,
            email: values.email,
            phoneNumber: values.phoneNumber
        };

        if (address.line1 && address.city && address.state && address.postalCode) {
            data.address = address;
        }

        if (dob) {
            data.dob = dob;
        }

        if (data.phoneNumber) {
            data.phoneNumber = data.phoneNumber.trim();
        }

        if (data.email) {
            data.email = data.email.trim();
        }

        this.setState({
            loading: true
        });

        if (this.props.isAdmin) {
            this.props.dispatch(updateUserAdmin({
                userId: this.props.userId,
                data: data,
                onSuccess: () => {
                    this.setState({
                        loading: false
                    });
                }
            }));
        } else {
            if (values.password) {
                data.password = values.password;
            }

            this.props.dispatch(updateUser({
                data: data,
                onSuccess: () => {
                    this.setState({ loading: true });
                    this.props.dispatch(updateStripePerson({
                        organisationId: this.props.organisationId,
                        data: data,
                        onSuccess: () => {
                            this.stopLoading();
                            if (this.props.nextPage) {
                                this.props.nextPage();
                            }
                        }
                    }));
                }
            }));
        }
    };

    validate = (values) => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = "Required";
        }

        if (!values.lastName) {
            errors.lastName = "Required";
        }

        if (!values.email) {
            errors.email = "Required";
        }

        if (!values.phoneNumber) {
            errors.phoneNumber = "Required";
        }

        if (!values.dateOfBirth) {
            errors.dateOfBirth = "Required";
        }

        if (!values.line1) {
            errors.line1 = "Required";
        }

        if (!values.city) {
            errors.city = "Required";
        }

        if (!values.postalCode) {
            errors.postalCode = "Required";
        }

        if (!values.state) {
            errors.state = "Required";
        }

        if (!values.number) {
            errors.number = "Required";
        }

        if (!values.verificationDocumentFront) {
            errors.verificationDocumentFront = "Required";
        }

        if (values.idType && values.idType === "driversLicence" && !values.verificationDocumentBack) {
            errors.verificationDocumentBack = "Required";
        }

        if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = "Invalid email address";
        }

        if (values.password) {
            if (values.password.length < 6) {
                errors.password = "Password must have more than 6 characters";
            }

            if (values.confirmPassword && values.password !== values.confirmPassword) {
                errors.confirmPassword = "Passwords don't match";
            }

            if (!values.confirmPassword) {
                errors.confirmPassword = "Passwords don't match";
            }
        }

        if (values.dateOfBirth) {
            if (values.dateOfBirth.trim().length < 10) {
                errors.dateOfBirth = "Please enter a valid date of birth";
            }

            if (values.dateOfBirth.trim().length === 10) {
                const dob = moment(values.dateOfBirth, "DD/MM/YYYY");
                const today = moment();
                if (today.diff(dob, "year") < 18) {
                    errors.dateOfBirth = "You must be at least 18 years old.";
                }

                if (today.year() - dob.year() > 100) {
                    errors.dateOfBirth = "Invalid date of birth";
                }
            }
        }

        return errors;
    };

    uploadIdDocumentFront = (formData, onSuccess, onError) => {
        formData.append("purpose", "identity_document");

        this.props.dispatch(uploadIdDocument({
            organisationId: this.props.organisationId,
            formData: formData,
            onSuccess: onSuccess,
            onError: onError
        }));
    };

    renderIdFields = (props) => {
        if (this.props.user.verificationDocumentFrontId) {
            return (
                <div className={ styles.id_provided }>
                    <label>ID Type</label>
                    <Chip green>Provided</Chip>
                </div>
            );
        }

        return (
            <>
                <Field name={ "idType" } { ...props }>
                    { (fieldProps) => {
                        return (
                            <RadioGroup initialValue={ "passport" } { ...fieldProps } text={ "Id Type" }>
                                <RadioInput id={ "passport" }
                                    name={ "passport" }
                                    text={ "Passport" }
                                    defaultChecked
                                    value={ "passport" }
                                />

                                <RadioInput id={ "driversLicence" }
                                    name={ "driversLicence" }
                                    text={ "Driver's Licence" }
                                    value={ "driversLicence" }
                                />
                            </RadioGroup>
                        );
                    } }
                </Field>

                <Field name={ "number" }
                    id={ "idNumber" }
                    label={ "Id Number" }
                    component={ Input }
                />

                <Field id={ "verificationDocumentFront" }
                    name={ "verificationDocumentFront" }
                    label={ "Front" }
                    elementId={ "verificationDocumentFront" }
                    previewId={ "front" }
                    component={ FileInput }
                    uploadFile={ this.uploadIdDocumentFront }
                    loadManualPreview
                    { ...props }
                />

                { props.values.idType === "driversLicence" && (
                    <Field id={ "verificationDocumentBack" }
                        name={ "verificationDocumentBack" }
                        label={ "Back" }
                        elementId={ "verificationDocumentBack" }
                        previewId={ "back" }
                        component={ FileInput }
                        uploadFile={ this.uploadIdDocumentFront }
                        loadManualPreview
                        { ...props }
                    />

                ) }
            </>
        );
    };

    renderForm = (props) => {
        return (
            <div className={ styles.account_details }>
                <div className={ styles.form_container }>
                    <div className={ styles.form_content }>
                        <h2>Account Details</h2>
                        <Field id={ "firstName" }
                            name={ "firstName" }
                            label={ "First Name" }
                            placeholder={ "John" }
                            component={ Input }
                            validate={ this.validateFirstName }
                        />
                        <Field id={ "lastName" }
                            name={ "lastName" }
                            label={ "Last Name" }
                            placeholder={ "Smith" }
                            component={ Input }
                        />
                        <Field id={ "email" }
                            name={ "email" }
                            label={ "Contact Email" }
                            placeholder={ "example@email.com" }
                            component={ Input }
                        />
                        <NumberInputField name={ "phoneNumber" }
                            label={ "Contact Number" }
                            numberFormatProps={ {
                                format: "+64##########"
                            } }
                        />

                        <NumberInputField name={ "dateOfBirth" }
                            label={ "Date of birth" }
                            numberFormatProps={ {
                                format: "##/##/####",
                                placeholder: "DD/MM/YYYY"
                            } }
                        />

                        <AddressInputField name={ "line1" }
                            initialAddress={ this.props.initialValues.line1 }
                            label={ "Address" }
                        />

                        <Field id={ "state" }
                            name={ "state" }
                            label={ "Suburb" }
                            component={ Input }
                        />
                        <Field id={ "city" }
                            name={ "city" }
                            label={ "City" }
                            component={ Input }
                        />
                        <Field id={ "postalCode" }
                            name={ "postalCode" }
                            label={ "Post Code" }
                            component={ Input }
                        />

                        { this.props.showPassword && (
                            <React.Fragment>
                                <Field id={ "password" }
                                    name={ "password" }
                                    label={ "Password" }
                                    password
                                    placeholder={ "Type password here" }
                                    component={ Input }
                                />
                                <Field id={ "confirmPassword" }
                                    name={ "confirmPassword" }
                                    label={ "Confirm Password" }
                                    password
                                    placeholder={ "Type password here" }
                                    component={ Input }
                                />
                            </React.Fragment>
                        ) }

                        { this.renderIdFields(props) }

                    </div>
                </div>
                <div className={ styles.button }>
                    <Button blue
                        onPress={ props.handleSubmit }
                        disabled={ this.state.loading || this.props.loadingImages }
                        loading={ this.state.loading }>
                        { this.props.submitButtonText ? this.props.submitButtonText : "Next" }
                    </Button>
                </div>
            </div>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <Loading />
            );
        }

        return (
            <Formik initialValues={ this.props.initialValues }
                enableReinitialize
                validate={ this.validate }
                onSubmit={ this.onSubmit }
            >
                { (props) => this.renderForm(props) }
            </Formik>
        );
    }
}

export default withRouter(connect((state, props) => {
    const userProps = getUserProps(state, props);
    const user = userProps.user;
    const address = user.address || {};
    return {
        ...userProps,
        initialValues: {
            name: user.name ? user.name : "",
            firstName: user.firstName ? user.firstName : "",
            lastName: user.lastName ? user.lastName : "",
            email: user.email ? user.email : "",
            phoneNumber: user.phoneNumber ? user.phoneNumber : "",
            password: "",
            confirmPassword: "",
            currentPassword: "",
            idType: "",
            dateOfBirth: user.dateOfBirth ? user.dateOfBirth : "",
            line1: address.line1 ? address.line1 : "",
            state: address.state ? address.state : "",
            city: address.city ? address.city : "",
            postalCode: address.postalCode ? address.postalCode : "",
            verificationDocumentBack: "",
            verificationDocumentFront: "",
            number: ""
        },
        isAdmin: isAdmin(state.user),
        loadingImages: state.images.loadingImages
    };
})(AccountDetails));
