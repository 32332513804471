/* eslint-disable no-shadow */
import * as Types from "../actions/user";

const initialState = {};

export default function userOrganisations(state = initialState, action) {
    switch (action.type) {

        case Types.SET_USER_ORGANISATION: {
            const newState = { ...state };
            const organisation = action.payload;

            newState[organisation.id] = organisation;

            return newState;
        }


        case Types.SET_USER_ORGANISATIONS: {
            const newState = { ...state };
            const organisations = (action.payload || []);

            organisations.forEach((organisation) => {
                newState[organisation.id] = organisation;
            });

            return newState;
        }

        default: {
            return state;
        }
    }
}