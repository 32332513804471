import * as Types from "../actions/reports";

const initialState = {
    donations: {},
    allTimeDonations: {}
};

export default function reports(state = initialState, action = { type: null, payload: null }) {
    switch (action.type) {

        case Types.SET_CHARITY_DONATION_REPORT: {

            const payload = action.payload;
            const organisationId = payload.organisationId;
            const report = payload.report;

            return {
                ...state,
                donations: {
                    ...state.donations,
                    [ organisationId ]: report,
                },
            };
        }
        case Types.SET_ALL_TIME_CHARITY_DONATION_REPORT: {
            const payload = action.payload;
            const organisationId = payload.organisationId;
            const report = payload.report;

            return {
                ...state,
                allTimeDonations: {
                    ...state.allTimeDonations,
                    [ organisationId ]: report,
                },
            };
        }

        default: {
            return state;
        }
    }
}
