import * as Type from "../actions/snackbars";

const initialState = [];

export default function snackbars(state = initialState, action = { type: null, payload: null }) {
    switch (action.type) {
        case Type.ADD_SNACKBAR: {
            const config = action.payload;
            if (!config) {
                return state;
            }

            return [
                ...state,
                config
            ];
        }

        case Type.REMOVE_SNACKBAR: {
            return [ ...state ].filter((snackbar) => {
                return snackbar.id !== action.payload;
            });
        }
        default: {
            return state;
        }
    }
}