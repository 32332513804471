import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import { setActiveOrganisation } from "./settings";
import * as UsersAPI from "./api/user";
import { showError, showSuccess } from "./snackbars";

export const SET_USER = "user:SET_USER";
export const SET_USER_ORGANISATION = "user:SET_USER_ORGANISATION";
export const SET_USER_ORGANISATIONS = "user:SET_USER_ORGANISATIONS";

export const setUserOrganisations = createAction(SET_USER_ORGANISATIONS);

export const setUser = createAction(SET_USER);

export const getUserOrganisations = wrapper(UsersAPI.getUserOrganisationsAPI, (dispatch, organisations) => {
    dispatch(setActiveOrganisation((organisations[0] || { id: null }).id));
    dispatch(setUserOrganisations(organisations));
});

export const updateUserOrganisation = wrapper(UsersAPI.updateUserOrganisationAPI, (dispatch, organisations) => {
    dispatch(setActiveOrganisation((organisations[0] || { id: null }).id));
    dispatch(setUserOrganisations(organisations));
    dispatch(showSuccess("Charity updated successfully"));
}, (dispatch, error) => {
    dispatch(showError(error.message));
});

export const getUser = wrapper(UsersAPI.getUserAPI, (dispatch, user) => {
    dispatch(setUser(user));
});

export const updateUser = wrapper(UsersAPI.updateUserAPI, (dispatch, user) => {
    dispatch(setUser(user));
});

export const getUserIp = wrapper(UsersAPI.getUserIpAPI);
