import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/es/storage";
import * as SettingTypes from "../actions/settings";
import settings from "./settings";
import user from "./user";
import userOrganisations from "./userOrganisations";
import snackbars from "./snackbars";
import organisations from "./organisations";
import auth from "./auth";
import reports from "./reports";
import transactions from "./transactions";
import images from "./images";
import stripe from "./stripe";
import charityInfo from "./charityInfo";
import users from "./users";

export const PERSIST_CONFIG = {
    key: "root",
    storage: storage,
    whitelist: [
        "auth"
    ],
};

const appReducer = persistCombineReducers(PERSIST_CONFIG, {
    auth: auth,
    snackbars: snackbars,
    settings: settings,
    user: user,
    userOrganisations: userOrganisations,
    organisations: organisations,
    charityInfo: charityInfo,
    reports: reports,
    transactions: transactions,
    images: images,
    stripe: stripe,
    users: users,
});

const rootReducer = (state, action) => {

    if (action.type === SettingTypes.PURGE_REDUX_STORE) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
